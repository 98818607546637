import React from 'react';
import { TextField, InputAdornment, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchBarProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent) => Promise<void>;
}

const SearchBar: React.FC<SearchBarProps> = ({ value, onChange, onSubmit }) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit(event);
    }
  };

  return (
    <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          placeholder="Search username or id..."
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '50px', // Reduced height
              '& input': {
                padding: '10px 14px', // Adjust padding to reduce height
              },
              '& fieldset': {
                borderColor: '#FFFFFF',
              },
              '&:hover fieldset': {
                borderColor: '#FFFFFF',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#FFFFFF',
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit" style={{ backgroundColor: '#FFFFFF', width: '30px', height: '30px' }}>
                  <SearchIcon sx={{color: '#243447'}}/>
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ style: { color: '#FFFFFF' } }}
        />
    </Box>
  );
};

export default SearchBar;