import React, { useState, useCallback } from 'react';
import axios from 'axios';
import SearchBar from '../../../utils/SearchBar';
import {Box, Skeleton} from '@mui/material';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Carousel from "../../../utils/Carousel";
import CodeBox from "../../../utils/CodeBox";
import UserBox from "../../../utils/UserBox";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AdminStatBox from "../../../utils/AdminStatBox";
import Button from "@mui/material/Button";
import config from "../../../../config";
import { useSnackbar } from 'notistack';
import EmailBox from "../../../utils/EmailBox";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MembershipBox from "../../../utils/MembershipBox";
import DialogWithWaveBackground from "../../../utils/DialogWithWaveBackground";
import AddBalanceForm from "../../../utils/Forms/AddBalanceForm";
import ModifyUserForm from "../../../utils/Forms/ModifyUserForm";
import TransactionsTable from "../../../utils/Tables/TransactionsTable";


const SearchUserInfo = () => {
    const [results, setResults] = useState({
        name: '',
        username: '',
        phone_number: '',
        email: '',
        register_date: '',
        ref_id: '',
        reinvestment_wallet: '',
        deposit_wallet: '',
        total_prize: '',
        withdraw_wallet: '',
        savings_wallet: '',
        store_wallet: '',
        promotional_wallet: '',
        membership: '',
        membership_date: '',
        total_deposit: '',
        cycle_gains: '',
        referrals_gains: '',
        parent_id: '',
        leader_gains: '',
        total_packs: [] as {
            name: string;
            percentage: number;
            price: number;
            date: string;
            day_percentage: number;
            wallet: string;
            description: string[];
            referral_bonus: string[];
        }[]
    });

    const [userTransactions, setUserTransactions] = useState([{
        amount: 0,
        date: '',
        description: '',
        operation: 0,
        ref: ''
    }]);

    const location = useLocation();
    const [searchValue, setSearchValue] = useState(new URLSearchParams(location.search).get('search') || '');
    const [hasResult, setHasResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [openAdd, setOpenAdd] = useState(false);
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    const [openModify, setOpenModify] = useState(false);
    const handleOpenModify = () => setOpenModify(true);
    const handleCloseModify = () => setOpenModify(false);


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const getUserTransactions = async () => {
        await axios.post(`${config.baseURL}/api/search/transactions`, { query: searchValue })
            .then(response => {
                if (response.status === 200) {
                    setUserTransactions(response.data.data);
                    console.log(response.data.data);
                    }

            }).catch(error => {
                console.error('Error:', error);
            });
    }

    const handleSubmit = useCallback(async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${config.baseURL}/api/search`, { query: searchValue });
            const data = response.data;
            const transformedSubproducts = data.total_packs.map((total_packs: any) => ({
                name: total_packs.name,
                percentage: total_packs.percentage,
                price: total_packs.price,
                date: total_packs.date,
                day_percentage: total_packs.day_percentage,
                wallet: total_packs.wallet,
                description: total_packs.description,
                referral_bonus: total_packs.referral_bonus
            }));
            setResults({ ...data, subproducts: transformedSubproducts });
            await getUserTransactions();

            setHasResult(true);

        } catch (response: any) {
            setHasResult(false);
            enqueueSnackbar('User not found', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [enqueueSnackbar, getUserTransactions, searchValue]);

    return (
        <Box flexGrow={1} m="10px">
            <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{xs: '10px', sm: '10px', md: '10px'}}>
                <Grid xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px',
                        backgroundColor: '#285c9a',
                        minHeight: '96px'
                    }} width="100%" height="100%" paddingRight={"23px"}>
                        <Box sx={{alignItems: 'left'}}>
                            {hasResult && (
                                <>
                                    <Button sx={{
                                        backgroundColor: '#ffffff', marginRight: '10px', fontWeight: 'bold',
                                        '&:hover': {
                                            backgroundColor: '#96a4b6',
                                        }
                                    }} onClick={handleOpenAdd}>
                                        <AddCircleIcon sx={{marginRight: '10px'}}/>
                                        Add balance
                                    </Button>
                                    <Button sx={{
                                        backgroundColor: '#ffffff', marginRight: '10px', fontWeight: 'bold',
                                        '&:hover': {
                                            backgroundColor: '#96a4b6',
                                        }
                                    }} onClick={handleOpenModify}>
                                        <AccountCircleIcon sx={{marginRight: '10px'}}/>
                                        Modify user
                                    </Button>
                                </>
                            )}
                        </Box>
                        <Box sx={{alignItems: 'right'}}>
                            <SearchBar value={searchValue} onChange={handleSearchChange} onSubmit={handleSubmit}/>
                        </Box>
                    </Box>
                </Grid>
                {loading ? (
                    <Grid container alignItems="flex-start" xs={12} md={12}>
                        <Grid xs={12} md={4}>
                            <Skeleton variant="rectangular" width="100%" height={130}/>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Skeleton variant="rectangular" width="100%" height={130}/>
                        </Grid>
                        <Grid xs={6} md={2}>
                            <Skeleton variant="rectangular" width="100%" height={130}/>
                        </Grid>
                        <Grid xs={6} md={2}>
                            <Skeleton variant="rectangular" width="100%" height={130}/>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Skeleton variant="rectangular" width="100%" height={487}/>
                        </Grid>
                        <Grid container xs={12} md={8} lg={8} alignItems="flex-start">
                            <Grid xs={12} md={3}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={4.5}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={4.5}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={2}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Skeleton variant="rectangular" width="100%" height={130}/>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <Skeleton variant={'rectangular'} width={'100%'} height={300}/>
                        </Grid>
                    </Grid>
                ) : hasResult && (
                    <Grid container alignItems="flex-start">
                        <Grid xs={12} md={4}>
                            <UserBox fullname={results.name} username={results.username} phone={results.phone_number}/>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <EmailBox email={results.email} register={results.register_date}/>
                        </Grid>
                        <Grid xs={6} md={2}>
                            <CodeBox code={results.ref_id}/>
                        </Grid>
                        <Grid xs={6} md={2}>
                            <CodeBox code={results.parent_id} isParent={true}/>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Carousel activePacks={results.total_packs}/>
                        </Grid>
                        <Grid container xs={12} md={8} lg={8} alignItems="flex-start">
                            <Grid xs={12} md={3}>
                                <AdminStatBox amount={results.total_packs.length.toString()} title={'Active Packs'}
                                              icon={<AddBoxIcon/>} monetary={false}/>
                            </Grid>
                            <Grid xs={12} md={4.5}>
                                <AdminStatBox amount={results.reinvestment_wallet} title={'Repurchase Wallet'}
                                              icon={<AccountBalanceWalletIcon/>} monetary={true}/>
                            </Grid>
                            <Grid xs={12} md={4.5}>
                                <AdminStatBox amount={results.deposit_wallet} title={'Deposit Wallet'}
                                              icon={<AccountBalanceWalletIcon/>} monetary={true}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <AdminStatBox amount={results.total_prize || '0'} title={'Volume Prize'}
                                              icon={<EmojiEvents/>} monetary={true}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <AdminStatBox amount={results.withdraw_wallet} title={'Withdraw Wallet'}
                                              icon={<AccountBalanceWalletIcon/>} monetary={true}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <AdminStatBox amount={results.savings_wallet} title={'Savings Wallet'}
                                              icon={<EmojiEvents/>} monetary={true}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <AdminStatBox amount={results.store_wallet} title={'Store Wallet'} icon={<EmojiEvents/>}
                                              monetary={true}/>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <AdminStatBox amount={results.promotional_wallet} title={'Promotional Wallet'}
                                              icon={<EmojiEvents/>} monetary={true}/>
                            </Grid>
                            <Grid xs={6} md={3}>
                                <MembershipBox membership={results.membership} register={results.membership_date}/>
                            </Grid>
                            <Grid xs={6} md={2}>
                                <AdminStatBox amount={results.leader_gains} title={'Leader Gains'}
                                              icon={<EmojiEvents/>} monetary={true}/>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <AdminStatBox amount={results.total_deposit || '0'} title={'Total Deposits'}
                                              icon={<TrendingUpOutlinedIcon/>} monetary={true}/>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <AdminStatBox amount={results.cycle_gains} title={'Cycle Gains'}
                                              icon={<TrendingUpOutlinedIcon/>} monetary={true}/>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <AdminStatBox amount={results.referrals_gains} title={'Referral Gains'}
                                              icon={<TrendingUpOutlinedIcon/>} monetary={true}/>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <TransactionsTable rows={userTransactions}/>
                        </Grid>
                    </Grid>
                )}
            </Grid>
                <DialogWithWaveBackground open={openAdd} onClose={handleCloseAdd}>
                    <AddBalanceForm username={results.username} onClose={handleCloseAdd}/>
                </DialogWithWaveBackground>

            <DialogWithWaveBackground open={openModify} onClose={handleCloseModify}>
                <ModifyUserForm username={results.username} onClose={handleCloseModify} />
            </DialogWithWaveBackground>
        </Box>
    );
};

export default SearchUserInfo;