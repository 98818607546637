import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../../../../Instances/axiosInstance";
import PageBanner from "../../../utils/PageBanner";
import ActivePack from "../../../utils/ActivePack";


const ActivePacks = () => {
  const [activePacks, setActivePacks] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();


  async function getActivePacks() {
    try {
      const response = await axiosInstance.get('/api/v1/info/user/active_packs');
      setIsLoading(false);
      setActivePacks(response.data);
      console.log('Active Packs:', response.data);
    } catch (error) {
      console.error(error.response.data.msg);
    }
  }

  useEffect(() => {
    getActivePacks()
  }, []);

  return (
    <Box m="10px">
      <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
        <Grid xs={12}>
          <PageBanner title={t('activePacks_title')} subtitle={t('activePacks_subtitle')} isLoading={isLoading}/>
        </Grid>
      {isLoading ? (
          <Grid xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
              <CircularProgress />
            </Box>
          </Grid>
      ) : (
        <Grid container spacing={2}>
          {activePacks.map((product, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ActivePack
                    progress={product.percentage * 100}
                    name={product.name}
                    amount={parseFloat(product.price)}
                />
              </Grid>
          ))}
        </Grid>
      )}
      </Grid>
    </Box>

  );
};

export default ActivePacks;