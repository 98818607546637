import React, { useState } from 'react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Box, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import '../../assets/css/AuditTable.css';
import CustomToolbar from './CustomToolbar';
import {useTranslation} from "react-i18next";

interface Row {
    name: string;
    email: string;
    phone: string;
    code: number;
    join_date: number;
    username: string;
    rank: string;
    reinvesting: number;
    packs_volume: number;
}

interface AuditTableProps {
    rows: Row[];
}

const ReferralsTable: React.FC<AuditTableProps> = ({rows}) => {
    const { t } = useTranslation();
    const [selectedRow, setSelectedRow] = useState<Row | null>(null);
    const handleClose = () => setSelectedRow(null);
    const columns: GridColDef[] = [
        { field: 'code', headerName: t("referrer_code"), flex: 1},
        { field: 'username', headerName: t("username"), flex: 1 },
        { field: 'join_date', headerName: t("join_date"), flex: 1 },
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }} className="data-grid-theme">
            <DataGrid
                rows={rows}
                columns={columns}
                density='compact'
                components={{
                    Toolbar: CustomToolbar,
                }}
                componentsProps={{
                    toolbar: { position: 'bottom' },
                }}
                getRowId={(row) => row.code}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
            />
            {/*<Dialog open={!!selectedRow} onClose={handleClose}>*/}
            {/*    <DialogTitle>Row Details</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        {selectedRow && (*/}
            {/*            <Box>*/}
            {/*                <Typography>date: {selectedRow.date}</Typography>*/}
            {/*                <Typography>ref: {selectedRow.ref}</Typography>*/}
            {/*                <Typography>description: {selectedRow.description}</Typography>*/}
            {/*                <Typography>amount: {selectedRow.amount}</Typography>*/}
            {/*            </Box>*/}
            {/*        )}*/}
            {/*    </DialogContent>*/}
            {/*</Dialog>*/}
        </Box>
    );
}

export default ReferralsTable;