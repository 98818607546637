import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import axios from 'axios';
import config from '../../../../config';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import PageBanner from "../../../utils/PageBanner";
import WalletStatBox from "../../../utils/WalletStatBox";
import axiosInstance from "../../../../Instances/axiosInstance";


const Withdraw = (props) => {
  const theme = useTheme();
  const [isLoading2FA, setIsLoading2FA] = useState(true);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [amount, setAmount] = useState('');
  const [reinvestmentAmount, setReinvestmentAmount] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [open, setOpen] = useState(false);
  const [activeWithdraw, setActiveWithdraw] = useState(true);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    ref_id: '',
    balance: { for_savings: 0.00, for_products: 0.00, for_withdraw: 0.00, total: 0.00 },
  });
  const [token, setToken] = useState('');
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [open2FA, setOpen2FA] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [backendMessage, setBackendMessage] = useState('');
  const [backendMessage2FA, setBackendMessage2FA] = useState('');
  const [transferWallet, setTransferWallet] = useState('reinvestment');
  const [withdrawData, setWithdrawData] = useState({fee: 0, is_available: false});
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const handleOpen2FA = async () => {
    setIsLoading2FA(true);
    setOpen2FA(true);
    await axiosInstance.post(`/api/v1/security/token`,
      { message: "request_2fa" },
    ).then(response => {
      setBackendMessage2FA(response.data.message);
      setIsLoading2FA(false);
    }).catch(error => {
      setBackendMessage2FA(error.response.data.message);
      setIsLoading2FA(false);
    });
  };

  const getWithdrawData = async () => {
    await axiosInstance.get(
      `/api/v1/info/withdraw`).then(response => {
          setWithdrawData(response.data);
          setIsLoading(false);
    }).catch(error => {
      console.error('Error:', error);
    })
  }

  const handleVerify2FA = async () => {
    setIsLoading2FA(true);
    await axiosInstance.post(`/api/v1/security/token`,
      { message: "verify_2fa", code: otpCode },

    ).then(response => {
      setBackendMessage2FA(response.data.message);
      handleWithdraw();
      setIsLoading2FA(false);
      setOpen2FA(false);

    }).catch(error => {
      setBackendMessage2FA(error.response.data.message);
      setIsLoading2FA(false);
    });
  };

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
  };

  const getData = async () => {
    await axiosInstance.get(`/api/v1/info/user/dashboard`,

    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setUserData(response.data);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const handleWithdraw = async () => {
    setIsLoadingDialog(true);
    setOpenMessageDialog(true);

    await axiosInstance.post(`/api/v1/funds/withdraw`,
      {
        amount: amount,
        wallet: walletAddress,
      },
      ).then(response => {
      // Open the Snackbar
      setBackendMessage(response.data.message);
      setIsLoadingDialog(false);
    }).catch(error => {
      console.log(error);
      // Open the Snackbar
      setBackendMessage(error.response.data.message);
      setIsLoadingDialog(false);
    });
  };

  const handleTransfer = async () => {
    setOpenMessageDialog(true);
    setIsLoadingDialog(true);
    await axiosInstance.post(`/api/v1/funds/transfer`,
      {
        wallet: transferWallet,
        amount: reinvestmentAmount
      },
      ).then(response => {
      // Open the Snackbar
      setBackendMessage(response.data.message);
      setIsLoadingDialog(false);

    }).catch(error => {
      console.log(error);
      // Open the Snackbar
      setBackendMessage(error.response.data.message);
      setIsLoadingDialog(false);
    });
  };

  useEffect(() => {
    getData().then(r => console.log(r));
    getWithdrawData().then(r => console.log(r));
  }, []);

  return (
    <Box m="10px">
      {/* HEADER */}
      <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
        <Grid xs={12}>
          <PageBanner title={t('withdraw_page ')} subtitle={t('withdraw_subtitle')} isLoading={isLoading}/>
        </Grid>
      {isLoading ? (
          <Grid xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
              <CircularProgress />
            </Box>
          </Grid>
      ) : (
        <><Grid container spacing={2} xs={12}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <WalletStatBox
                amount={userData.balance.for_withdraw}
                title={t("dashboard_withdraw_funds")}
                icon={<AccountBalanceWalletIcon />}
                isLoading={isLoading}
                inmuted={true}
              />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <WalletStatBox
                amount={userData.balance.reinvestment}
                title={t("reinvestment_funds")}
                icon={<AccountBalanceWalletIcon />}
                isLoading={isLoading}
                inmuted={true}
              />
          </Grid>
        </Grid>
          <Box width={'100%'} height={'100%'} alignItems={'center'}>
          <Box display="flex" justifyContent="space-between">
            <Box width={'50%'} height={'100%'}>
              <Box bgcolor={'#f5f5f5'} p={'20px'} borderRadius={'10px'} m={'20px'}>
                <Typography variant="h4" align="center">{t("withdraw_form_label1")}</Typography>
                <Typography variant={'h6'} align="center">{t("withdraw_form_label2")}</Typography>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="center" m={'20px 0 0 0'}>
                <TextField
                  label={t("amount_to_withdraw")}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  variant="outlined"
                  style={{ marginBottom: '20px' }}
                  type={'number'}
                  required={true} />
              </Box>
              <Box display="flex" justifyContent="center">
                <TextField
                  label={t("withdraw_wallet")}
                  value={walletAddress}
                  onChange={(e) => setWalletAddress(e.target.value)}
                  variant="outlined"
                  style={{ marginBottom: '20px', width: '70%', color: '#285c9a' }}
                  required={true} />
              </Box>
              <Box display="flex" justifyContent="center">
                {withdrawData.is_available ? (
                  <Button variant={'contained'} color={'primary'} justifyContent={'center'} onClick={handleOpen2FA}
                          disabled={!amount || !walletAddress}>
                    {t("set_withdraw_order")}
                  </Button>
                ) : (
                  <Button disabled={true} variant={'contained'} color={'primary'} justifyContent={'center'}>
                    {t("set_withdraw_order")}
                  </Button>
                )}
              </Box>
            </Box>
            <Box width={'50%'} height={'100%'}>
              <Box bgcolor={'#f5f5f5'} p={'20px'} borderRadius={'10px'} m={'20px'}>
                <Typography variant="h4" align="center">{t("transfer_funds_label1")}</Typography>
                <Typography variant={'h6'} align="center">{t("transfer_funds_label2")}</Typography>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="center" m={'20px 0 0 0'}>
                <TextField
                  label={t("amount_to_transfer")}
                  value={reinvestmentAmount}
                  onChange={(e) => setReinvestmentAmount(e.target.value)}
                  variant="outlined"
                  style={{ marginBottom: '20px' }}
                  type={'number'}
                  required={true} />
              </Box>
              <Box display={'flex'} justifyContent={'center'}>
                <Select
                  value={transferWallet}
                  onChange={(e) => setTransferWallet(e.target.value)}
                  fullWidth
                  style={{ marginBottom: '20px', width: '70%' }}
                >
                  <MenuItem value={'store'}>{t("to_store_wallet")}</MenuItem>
                  <MenuItem value={'reinvestment'}>{t("to_reinvestment_wallet")}</MenuItem>
                </Select>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button variant={'contained'} color={'primary'} justifyContent={'center'} onClick={handleTransfer}
                        disabled={!reinvestmentAmount}>
                  {t("transfer_button")}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box bgcolor={'#f5f5f5'} p={'20px'} borderRadius={'10px'} m={'20px'}>
            <Typography m={"5px"} variant="h3" align="center">{t("withdraw_info_label1")}</Typography>
            <Typography m={"5px"} variant={'h4'} align="center">{t("withdraw_info_label2")}</Typography>
            {/*<Typography variant={'h4'} align="center">{t("withdraw_info_label3") + withdrawData.fee}%</Typography>*/}
            <Typography m={"5px"} variant={'h4'} align="center">{t("withdraw_info_label3")} 2%</Typography>
            <Typography m={"5px"} variant={'h4'} align="center" color={"red"} sx={{ fontWeight: 'bold' }}>{t("withdraw_promotion")}</Typography>

          </Box>

          <Dialog open={openMessageDialog} onClose={handleCloseMessageDialog}>
            {isLoadingDialog ? (
              <Box display="flex" justifyContent="center" alignItems="center" m={'10px'}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <DialogContent>
                  <Typography variant={'h3'}>
                    {t(backendMessage)}
                  </Typography>
                </DialogContent><DialogActions>
                <Button onClick={handleCloseMessageDialog} color="primary">
                  {t("close_button")}
                </Button>
              </DialogActions>
              </>
            )}
          </Dialog>

          <Dialog open={open2FA} onClose={() => setOpen2FA(false)}>
            {isLoading2FA ? (
              <Box display="flex" justifyContent="center" alignItems="center" m={'10px'}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <DialogContent m={'10px'}>
                  <TextField
                    label={t("verification_code")}
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                    variant="outlined"
                    required={true}
                    type={'number'}
                    m={'20px'} /><Typography variant="h5" color="textPrimary"
                                             sx={{ m: 2 }}>{backendMessage2FA}</Typography>
                </DialogContent><DialogActions>
                <Button onClick={() => setOpen2FA(false)}>{t("cancel_button")}</Button>
                <Button onClick={handleVerify2FA}>{t("submit_button")}</Button>
              </DialogActions>
              </>
            )}
          </Dialog>

        </Box></>
)}
      </Grid>
    </Box>
  );
};

export default Withdraw;