import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack'; // Import useSnackbar

interface CodeBoxProps {
    email: string;
    register: string;
}

const EmailBox: React.FC<CodeBoxProps> = ({ email, register}) => {
    const { enqueueSnackbar } = useSnackbar(); // Use the hook

    const handleClick = () => {
        navigator.clipboard.writeText(email);
        enqueueSnackbar('User email copied to clipboard!', { variant: 'info' }); // Show snackbar
    };

    return (
        <Box sx={{
            // Styling for the box
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            padding: '10px',
            backgroundColor: '#285c9a',
            minHeight: '130px',
            cursor: 'pointer'
        }} onClick={handleClick}>
            <Box display="flex">
                <Box>
                    <Typography
                        fontSize={'20px'}
                        fontWeight="bold"
                        sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}
                    >
                        User email:
                    </Typography>
                </Box>

            </Box>
            <Box>
                <Typography fontSize={'28px'} fontWeight="bold" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}>
                    {email}
                </Typography>
                <Typography fontSize={'16px'} fontWeight="bold" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}>
                    Registered at: {register}
                </Typography>
            </Box>
        </Box>
    );
};

export default EmailBox;