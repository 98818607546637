import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import config from '../../../../config';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

const RankPrizes = (props) => {
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const colors = {redAccent: {500: '#f44336'}, greenAccent: {300: '#4caf10'}, primary: {400: '#285c9a'}};
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [prizes, setPrizes] = React.useState([]);
  const { t, i18n } = useTranslation();

  const getData = async () => {
    await axios.get(
      `${config.baseURL}/api/v1/info/prizes`
    ).then(response => {
        setPrizes(response.data);
        setIsLoading(false);

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: t("rank_name"),
      width: 200,
      cellClassName: 'name-column--cell',
      renderCell: ({ row: { name } }) => {
        return <Typography> {t(name)} </Typography>;
      }
    },
    {
      field: 'volume',
      headerName: t("needed_volume"),
      width: 200,
      renderCell: ({ row: { volume } }) => {
        return <Typography> {parseFloat(volume).toLocaleString()} </Typography>;
      }
      },
    {
      field: 'prize',
      headerName: t("prize"),
      width: 100,
      renderCell: ({ row: { prize } }) => {
        if (prize === '0') {
          return <Typography> {t("a_car")} </Typography>;
        }
        else {
          return <Typography> ${parseFloat(prize).toLocaleString()} </Typography>;
        }
      }
    },
  ];

  useEffect(() => {
    getData().then(r => console.log(r));
  }, []);


  return (
        <Box m="10px">

          <Box
            display={smScreen ? 'flex' : 'block'}
            flexDirection={smScreen ? 'row' : 'column'}
            justifyContent={smScreen ? 'space-between' : 'start'}
            alignItems={smScreen ? 'center' : 'start'}
            m="10px 0"
            p="20px"
            backgroundColor="#285c9a"
          >
            <Box>
              <Typography
                variant="h2"
                color="#FFFFFF"
                fontWeight="bold"
                sx={{ mb: '5px' }}
              >
                {t("rank_prizes_title")}
              </Typography>
              <Typography variant="h5" color="#FFFFFF">
                {t("rank_prizes_subtitle")}
              </Typography>
            </Box>
          </Box>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
              <CircularProgress />
            </Box>
          ) : (

          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="50%"
            height="72vh"
            sx={{
              '& .MuiDataGrid-root': {
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: 'none',
              },
              '& .name-column--cell': {
                color: colors.greenAccent[300],
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#285c9a',
                borderBottom: 'none',
              },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: colors.primary[400],
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: 'none',
                backgroundColor: '#285c9a',
              },
              '& .MuiCheckbox-root': {
                color: `${colors.greenAccent[200]} !important`,
              },
              '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              rows={prizes}
              columns={columns}
              getRowId={(row) => row.name}
              loading={isLoading}
              hideFooterPagination={true}
              components={{
                Toolbar: null,
                LoadingOverlay: function CustomLoadingOverlay() {
                  return <CircularProgress />;
                },
              }}
            />
          </Box>
          </Box>
          )}
        </Box>
  );
};

export default RankPrizes;