import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const CountdownBox = () => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeRemaining = calculateTimeRemaining();
      if (newTimeRemaining.hours === 23 && newTimeRemaining.minutes === 59 && newTimeRemaining.seconds === 59) {
        enqueueSnackbar('Countdown restarted to next 5 PM!', { variant: 'info' });
      }
      setTimeRemaining(newTimeRemaining);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [enqueueSnackbar]);

  function calculateTimeRemaining() {
    const now = new Date();
    const losAngelesTime = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/Los_Angeles',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).formatToParts(now);

    const hourPart = losAngelesTime.find(part => part.type === 'hour');
    const hour = hourPart ? parseInt(hourPart.value) : 0;

    const target = new Date();
    target.setHours(17, 0, 0, 0); // Set target to 5 PM today in Los Angeles time

    // If it's already past 5 PM in Los Angeles, set target to 5 PM of the next weekday
    if (hour >= 17) {
      target.setDate(target.getDate() + 1);
    }

    // Skip weekends
    while (target.getDay() === 0 || target.getDay() === 6) {
      target.setDate(target.getDate() + 1);
    }

    const timeDiff = target.getTime() - now.getTime();
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  }

  const totalSeconds = 24 * 60 * 60; // Total seconds in a day
  const remainingSeconds = timeRemaining.hours * 3600 + timeRemaining.minutes * 60 + timeRemaining.seconds;
  const percentage = (remainingSeconds / totalSeconds) * 100;

  const data = [
    { name: 'Remaining', value: percentage },
    { name: 'Elapsed', value: 100 - percentage },
  ];

  const COLORS = ['#0088FE', '#00C49F'];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFFFFF', textAlign: 'center', height: '100%', width: '100%' }}>
      <Box justifyContent={'center'}>
        <Box>
          <Typography variant="h5">
            {t('Next bonus on:')}
          </Typography>
        </Box>

        <Typography variant="h4">
          {`${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`}
        </Typography>
        <Typography variant="body1">
          {t('Los Angeles Time')}
        </Typography>
      </Box>
    </Box>
  );
};

export default CountdownBox;