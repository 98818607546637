// src/components/pages/login/Login.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import axiosInstance from '../../../Instances/axiosInstance';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    CircularProgress,
    DialogContent,
    Typography, Dialog
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import config from "../../../config";
import {
    Body,
    ButtonIcon,
    Container,
    ErrorText,
    InputWrapper,
    LoginField,
    LoginForgetPassword,
    LoginForm,
    LoginIcon,
    LoginInput,
    LoginSubmitButton,
    MainLogo,
    Screen,
    ScreenBackground,
    ScreenBackgroundShape1,
    ScreenBackgroundShape2,
    ScreenBackgroundShape3,
    ScreenBackgroundShape4,
    ScreenContent,
    SocialIcon, SocialIconsDiv,
} from './LoginStyles';
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import USFlag from '../../assets/USFlag.svg';
import SpainFlag from '../../assets/SpainFlag.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const Login: React.FC = () => {
    const [username, setusername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const supportForm = {
        values: {
            email: '',
            message: '',
        },
        handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;
            supportForm.values = {
                ...supportForm.values,
                [name]: value,
            };
        },
    };
    const {t} = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const bcrypt = require('bcryptjs');

    const toggleLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'es' : 'en';
        i18n.changeLanguage(newLanguage);
        setCurrentLanguage(newLanguage);
    }

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const hashedPassword = bcrypt.hashSync(password, config.salt);
            const response = await axiosInstance.post('/api/v1/login', { username, password: hashedPassword });
            setToken(response.data.access_token);
            navigate('/home'); // Redirect to the dashboard or another page
        } catch (error) {
            console.error('Login failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSubmit = async (event: React.FormEvent) => {
        // TODO: Set up the form submission logic
        event.preventDefault();
        setIsLoading(true);
        try {
            await axiosInstance.post('/api/v1/form/support/unauthorized', { email: supportForm.values.email, message: supportForm.values.message });
            setSubmitted(true);
        } catch (error) {
            console.error('Login failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleIconClick = () => {
        setOpen(true);
    };

    return (
        <Body>
            <Container>
                <Screen>
                    <ScreenContent>
                        <MainLogo src={'./FullLogo.png'} />
                        <LoginForm onSubmit={handleLogin}>
                            <Box minHeight={'30px'}>
                                <ErrorText>{t(error)}</ErrorText>
                            </Box>
                            <LoginField>
                                <LoginInput
                                    name="username"
                                    value={username}
                                    onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setusername(e.target.value)}
                                    placeholder={t("username")}
                                    // clearOnEscape
                                    // size="large"
                                    type="text"
                                    required={true}
                                />
                                <LoginIcon>
                                    <PersonOutlineOutlinedIcon />
                                </LoginIcon>
                            </LoginField>
                            <LoginField>
                                <LoginInput
                                    name="password"
                                    value={password}
                                    onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setPassword(e.target.value)}
                                    placeholder={t("password")}
                                    // clearOnEscape
                                    // size="large"
                                    type={showPassword ? 'text' : 'password'}
                                    required={true}

                                />
                                <LoginIcon>
                                    <LockOutlinedIcon />
                                </LoginIcon>

                            </LoginField>

                            <LoginForgetPassword onClick={() => navigate('/forgot')}> {t("forgot_password")} </LoginForgetPassword>

                            <InputWrapper>
                                <LoginSubmitButton type="submit">
                                    {isLoading ? <CircularProgress size={30}/> : t("login")}
                                    <ButtonIcon>
                                        <ArrowForwardIosOutlinedIcon />
                                    </ButtonIcon>
                                </LoginSubmitButton>
                            </InputWrapper>
                            <InputWrapper>
                                <LoginSubmitButton onClick={() => navigate('/register')}>
                                    {t("register")}
                                    <ButtonIcon>
                                        <ArrowForwardIosOutlinedIcon />
                                    </ButtonIcon>
                                </LoginSubmitButton>
                            </InputWrapper>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box justifyContent={'center'} justifyItems={'center'}>
                                    <Box onClick={toggleLanguage} style={{ cursor: 'pointer' }}>
                                        {currentLanguage === 'en' ? <img src={SpainFlag} alt="Spain Flag" width="30px" height="30px"/> : <img src={USFlag} alt="US Flag" width="30px" height="30px"/>}
                                    </Box>
                                </Box>
                                <SocialIcon onClick={handleIconClick}> <HeadsetMicOutlinedIcon /> </SocialIcon>
                            </div>


                            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                                <Typography variant="h4" color="textPrimary" sx={{ m: 2 }}> {t("support_form_label4")} </Typography>
                                <form onSubmit={handleFormSubmit}>
                                    <DialogContent>
                                        <Box>
                                            {/*<TextField name="email" label="Contact email" fullWidth required m={'10px'} />*/}
                                            <TextField
                                                name={'email'}
                                                label={t("contact_email")}
                                                value={supportForm.values.email}
                                                onChange={supportForm.handleChange}
                                                variant="outlined"
                                                required={true}
                                                style={{ marginBottom: '20px', width: '100%', color: '#262626' }}
                                            />
                                        </Box>
                                        <Box m={'20px 0 0 0'}>
                                            {/*<TextField name="message" label="Problem or Question" fullWidth required />*/}
                                            <TextField
                                                name={'message'}
                                                label={t("problem_or_question")}
                                                value={supportForm.values.message}
                                                onChange={supportForm.handleChange}
                                                variant="outlined"
                                                required={true}
                                                style={{ marginBottom: '20px', width: '100%', color: '#262626' }}
                                            />
                                        </Box>
                                        {submitted &&
                                            <Typography variant="body1" color="success"> {t("support_form_label1")} </Typography>}
                                        <Typography variant="body1" color="textSecondary" sx={{ m: 2 }}>
                                            {t("support_form_label2")}
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary" sx={{ m: 2 }}>
                                            {t("support_form_label3")}
                                        </Typography>
                                        <Button onClick={() => setOpen(false)}>{t("close_button")}</Button>
                                        <Button type="submit">{t("submit_button")}</Button>
                                    </DialogContent>
                                </form>
                            </Dialog>

                        </LoginForm>


                    </ScreenContent>
                    <ScreenBackground>
                        <ScreenBackgroundShape4 />
                        <ScreenBackgroundShape3 />
                        <ScreenBackgroundShape2 />
                        <ScreenBackgroundShape1 />
                    </ScreenBackground>
                </Screen>
            </Container>
        </Body>
    );
};

export default Login;