import { Box, Typography } from '@mui/material';
import React from 'react';
import config from '../../config';
import { useSnackbar } from 'notistack'; // Import useSnackbar

interface CodeBoxProps {
    fullname: string;
    username: string;
    phone: string;
}

const CodeBox: React.FC<CodeBoxProps> = ({ fullname, username, phone }) => {
    const { enqueueSnackbar } = useSnackbar(); // Use the hook

    const handleClick = () => {
        navigator.clipboard.writeText(`${fullname} (${username})`);
        enqueueSnackbar('Username/full name copied to clipboard!', { variant: 'info' }); // Show snackbar
    };

    return (
        <Box sx={{
            // Styling for the box
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            padding: '10px',
            backgroundColor: '#285c9a',
            minHeight: '130px',
            cursor: 'pointer'
        }} onClick={handleClick}>
            <Box display="flex">
                <Box>
                    <Typography
                        fontSize={'20px'}
                        fontWeight="bold"
                        sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}
                    >
                        Username: {username}
                    </Typography>
                </Box>

            </Box>
            <Box>
                <Typography fontSize={'35px'} fontWeight="bold" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}>
                    {fullname}
                </Typography>
            </Box>
            <Box>
                <Typography fontSize={'18px'} fontWeight="bold" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}>
                    Phone: {phone}
                </Typography>
            </Box>
        </Box>
    );
};

export default CodeBox;