import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import axiosInstance, { setAuthToken } from '../Instances/axiosInstance';
import { useNavigate } from 'react-router-dom';

interface AuthContextProps {
    token: string | null;
    setToken: (token: string | null) => void;
    logout: () => void;
    loading: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [token, setTokenState] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const setToken = (token: string | null) => {
        setTokenState(token);
        setAuthToken(token);
        if (token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }
    };

    const logout = async () => {
        try {
            await axiosInstance.post('/api/v1/logout');
            setToken(null);
            navigate('/');
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setAuthToken(storedToken);
            setTokenState(storedToken); // Ensure state is updated
        }
        setLoading(false); // Token initialization complete
    }, []);

    return (
        <AuthContext.Provider value={{ token, setToken, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};