// src/components/forms/TechnicalSupportForm.tsx
import React, { useState } from 'react';
import { Box, Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import axiosInstance from "../../../Instances/axiosInstance";
import {useSnackbar} from "notistack";

interface TechnicalSupportFormProps {
    onClose: () => void;
}

const TechnicalSupportForm: React.FC<TechnicalSupportFormProps> = ({onClose}) => {
  const [message, setMessage] = useState('');
  const [errorType, setErrorType] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
      try {
            if (!message || !errorType) {
                enqueueSnackbar('Please fill in all fields', { variant: 'error' });
            }
            axiosInstance.post('/api/v1/form/support', { message: message + ' ' + errorType });
            enqueueSnackbar('Message sent successfully', { variant: 'success' });
            onClose();

      } catch (error: any) {
          enqueueSnackbar('An error has ocurred', { variant: 'error' });
      }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Technical Support Form
      </Typography>
      <TextField
        label="Message"
        multiline
        rows={4}
        fullWidth
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Select
        value={errorType}
        onChange={(e) => setErrorType(e.target.value)}
        displayEmpty
        fullWidth
        sx={{ mb: 2 }}
      >
        <MenuItem value="" disabled>
          Select Error Type
        </MenuItem>
        <MenuItem value="bug">Bug</MenuItem>
        <MenuItem value="performance">Performance Issue</MenuItem>
        <MenuItem value="other">Other</MenuItem>
      </Select>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};

export default TechnicalSupportForm;