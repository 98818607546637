// Assuming the structure of AdminBox component, add onClick prop handling

import React from 'react';
import { Box, Typography } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport'; // Example icon, adjust as necessary

// Extend props to include onClick
interface AdminBoxProps {
  title: string;
  icon?: React.ReactNode; // Optional icon prop
  onClick: () => void; // Add onClick prop
}

const AdminBox: React.FC<AdminBoxProps> = ({ title, icon = <BugReportIcon />, onClick }) => {
  return (
    <Box
      sx={{
        // Styling for the box
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        cursor: 'pointer', // Make it obvious it's clickable
        backgroundColor: '#285c9a',
        '&:hover': {
          backgroundColor: '#f0f0f0', // Example hover effect
        },
      }}
      onClick={onClick} // Use the onClick prop here
    >
      {icon}
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
};

export default AdminBox;