import { PieChart } from "@mui/x-charts/PieChart";
import {Box, IconButton, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

interface ActivePackProps {
    progress: number;
    name: string;
    amount: number;
}

const ActivePack: React.FC<ActivePackProps> = ({ progress, name, amount }) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');
    const isExtraMediumScreen = useMediaQuery('(max-width:1350px)');
    const isLargeScreen = useMediaQuery('(max-width:1600px)');
    const isExtraLargeScreen = useMediaQuery('(max-width:1601px)');

    const getFontSize = () => {
        if (isSmallScreen) return '20px';
        if (isMediumScreen) return '16px';
        if (isExtraMediumScreen) return '14px';
        if (isLargeScreen) return '16px';
        if (isExtraLargeScreen) return '20px';
        return '20px';
    };

    const getGradientColor = (name: string) => {
        switch (name) {
            case 'Enterprising':
                return 'linear-gradient(180deg, #f2f0f0 30%, #287233 90%)';
            case 'Executive':
                return 'linear-gradient(180deg, #f2f0f0 30%, #3E285C 90%)';
            case 'CEO':
                return 'linear-gradient(180deg, #f2f0f0 30%, #F6A700 90%)';

            case 'Promotional enterprising':
                return 'linear-gradient(180deg, #f2f0f0 30%, #06FA4F 90%)';
            case 'Promotional executive':
                return 'linear-gradient(180deg, #f2f0f0 30%, #DA06FA 90%)';
            case 'Promotional CEO':
                return 'linear-gradient(180deg, #f2f0f0 30%, #FAF206 90%)';
            default:
                return '#f2f0f0'; // default color if no match
        }
    };

    const getBlueGradientColor = (name: string) => {
        switch (name) {
            case 'Enterprising':
                return 'linear-gradient(180deg, #285c9a 30%, #287233 90%)';
            case 'Executive':
                return 'linear-gradient(180deg, #285c9a 30%, #3E285C 90%)';
            case 'CEO':
                return 'linear-gradient(180deg, #285c9a 30%, #F6A700 90%)';

            case 'Promotional enterprising':
                return 'linear-gradient(180deg, #285c9a 30%, #06FA4F 90%)';
            case 'Promotional executive':
                return 'linear-gradient(180deg, #285c9a 30%, #DA06FA 90%)';
            case 'Promotional CEO':
                return 'linear-gradient(180deg, #285c9a 30%, #FAF206 90%)';
            default:
                return '#f2f0f0'; // default color if no match
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '487px',
                background: getGradientColor(name),
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '20px',
                position: 'relative', // Add relative positioning
            }}
        >
            <Box
                m={'10px'}
                p={'10px'}
                sx={{
                    color: '#FFFFFF',
                    fontSize: getFontSize(),
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#285c9a',
                    textAlign: 'center',
                    borderRadius: '10px',
                }}
            >
                <Tooltip title="Information">
                    <Box sx={{cursor: 'pointer'}}>
                        Active {name} Pack Progress
                    </Box>
                </Tooltip>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}
            >
                <PieChart
                    series={[
                        {
                            data: [ { id: 0, value: 300 - progress, color: '#6870fa'}, { id: 1, value: progress, color: '#4cceac'}],
                            innerRadius: 60,
                            outerRadius: '100%',
                            startAngle: 360,
                            endAngle: -360,
                            valueFormatter: (d) => `${d.value}%`,
                        },
                        { data: [ { id: 0, value: 300 - progress, color: '#6870fa'},
                                { id: 1, value: progress, color: '#4cceac'}],
                            outerRadius: 50,
                            startAngle: 360,
                            endAngle: -360,

                        }
                    ]}
                    sx={{
                        width: '100%',
                        height: '100%',
                        paddingLeft: '70px',
                    }}
                />
                <Typography
                    sx={{
                        color: '#252525',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        position: 'absolute',
                        WebkitTextStroke: '0.25px #ffffff'
                    }}
                >
                    {progress.toFixed(2)}%
                </Typography>
            </Box>
            <Box sx={{
                color: '#f2f0f0',
                fontSize: getFontSize(),
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                background: getBlueGradientColor(name),
                textAlign: 'center',
                borderRadius: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginBottom: '20px',
            }}>
                <Box>
                    Purchase amount:
                </Box>
                ${amount.toLocaleString()}
            </Box>
        </Box>
    )
}

export default ActivePack;