import * as React from 'react';
import Box from '@mui/material/Box';
import { BarChart } from '@mui/x-charts/BarChart';
import {useEffect, useState} from "react";
import axiosInstance from "../../Instances/axiosInstance";
import {Skeleton} from "@mui/material";

const data = [
    { "month": "Jan", "Cycle Bonus": 100, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 600, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Feb", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Mar", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Apr", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "May", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Jun", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Jul", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Aug", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Sep", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Oct", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Nov", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" },
    { "month": "Dec", "Cycle Bonus": 0, "Cycle BonusColor": "hsl(296, 70%, 50%)", "Referral Bonus": 0, "Referral BonusColor": "hsl(97, 70%, 50%)" }
];


export default function BarAnimation() {
    const [chartData, setChartData] = useState(data);
    const [isLoading, setIsLoading] = useState(true);

    async function getGainsHistory() {
        try {
            const response = await axiosInstance.get('/api/v1/info/charts/bar-chart');
            setChartData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getGainsHistory();
    }, []);

    const highlightScope = {
        highlighted: 'series',
        faded: 'global',
    } as const;

    const series = [
        {
            label: 'Cycle Bonus',
            data: chartData.map(item => item["Cycle Bonus"]),
            color: chartData[0]["Cycle BonusColor"]
        },
        {
            label: 'Referral Bonus',
            data: chartData.map(item => item["Referral Bonus"]),
            color: chartData[0]["Referral BonusColor"]
        }
    ].map((s) => ({ ...s, highlightScope }));


    return (
        <>
            {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height="100%" animation="wave"/>
            ) : (
                <Box sx={{ width: '100%', height: '100%', background: '#FFFFFF' }}>
                    <BarChart
                        grid={{ vertical: true }}
                        layout="vertical"
                        series={series
                            .slice(0, 2)
                            .map((s) => ({ ...s, data: s.data.slice(0, 12) }))}
                    />
                </Box>
            )}
        </>
    );
}
