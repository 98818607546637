import React, { useEffect, useState } from 'react';
import {
    Typography,
    TextField,
    FormControl,
    Button,
    Box,
    Backdrop,
    CircularProgress
} from '@mui/material';
import axios from "axios";
import config from "../../../config";
import { useSnackbar } from "notistack";

interface ModifyUserFormProps {
    username: string;
    onClose: () => void;
}

const ModifyUserForm: React.FC<ModifyUserFormProps> = ({ username, onClose }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleNameChange = (event: any) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePhoneNumberChange = (event: any) => {
        setPhoneNumber(event.target.value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${config.baseURL}/api/modifyuser`, {
                username,
                name,
                email,
                phone_number: phoneNumber
            });
            console.log('Response:', response.data);
            onClose();
            enqueueSnackbar(`User information updated successfully`, { variant: 'success' });
        } catch (error) {
            console.error('Error updating user information:', error);
            enqueueSnackbar('Error updating user information', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setIsFormValid(name !== '' && email !== '' && phoneNumber !== '');
    }, [name, email, phoneNumber]);

    return (
        <Box>
            <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                Modify {username} account information
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <TextField
                    fullWidth
                    margin="normal"
                    label="firstname"
                    value={name}
                    onChange={handleNameChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="lastname"
                    value={name}
                    onChange={handleNameChange}
                />
            </Box>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                />
            <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} disabled={!isFormValid}>
                Submit
            </Button>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default ModifyUserForm;