import React from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useMediaQuery } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import { useUser } from '../../../context/UserContext';
import AccountMenu from '../AvatarIcon';
import typo from '../../assets/typo.svg';
import { SidebarContext } from '../Sidebar/SidebarContext';
import SupportMenu from '../SupportMenu'; // Import the new SupportMenu component

const Navbar: React.FC = () => {
    const { logout } = useAuth();
    const { user } = useUser();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { toggleSidebar } = React.useContext(SidebarContext);

    return (
        <AppBar position="static" sx={{ backgroundColor: '#dddddd' }}>
            <Toolbar>
                {isMobile && (
                    <IconButton onClick={toggleSidebar}>
                        <MenuIcon sx={{ color: '#093a8c' }} />
                    </IconButton>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '10px' }}>
                    <img
                        src={typo}
                        alt="logo"
                        style={{
                            width: 'auto',
                            height: '40px',
                            alignSelf: 'flex-start'
                        }}
                    />
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={logout}>
                    <LogoutOutlinedIcon sx={{ color: '#093a8c' }} />
                </IconButton>
                <SupportMenu /> {/* Use the new SupportMenu component */}
                <AccountMenu name={user?.name || 'Tech Blue'} role={user?.role || 'user'} />
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;