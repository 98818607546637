import axios from 'axios';
import config from "../config";

const axiosInstance = axios.create({
    baseURL: config.baseURL, // Replace with your API base URL
});

export const setAuthToken = (token: string | null) => {
    console.log('Setting token:', token);
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

// Add a request interceptor to log request details
if (process.env.NODE_ENV === 'development') {
    axiosInstance.interceptors.request.use(
        (config) => {
            console.log('Request in production:', config); // Debugging log
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            console.log('Response in production:', response); // Debugging log
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

export default axiosInstance;