import React, { useState, useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import Navbar from '../../utils/Navbar/Navbar';
import AdminSidebar from '../../utils/Sidebar/AdminSidebar';
import AdminPanel from './Admin';
import OnGoingAudits from './OnGoingAudits/OnGoingAudits'
import SearchUserInfo from "./Search/SearchUserInfo";
import SearchUserReferrals from "./Search/SearchUserReferrals";
import AddAudits from "./AddAudits/AddAudits";
import {Route, Routes} from "react-router-dom";

async function getUserRole() {
    return 'user'; // Replace this with your auth system
}

const Admin = () => {
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        // Assuming getUserRole is an async function fetching the user's role
        const fetchUserRole = async () => {
            const role = await getUserRole(); // Implement this function based on your auth system
            setUserRole(role);
        };

        fetchUserRole();
    }, []);

    return (
        <><CssBaseline/>
            <AdminSidebar>
                <div style={{height: '100%', width: '100%'}}>
                    <main>
                        <Navbar/>
                        <Routes>
                            <Route path={"/"} element={<AdminPanel/>} />
                            <Route path={"/audits"} element={<OnGoingAudits />} />
                            <Route path={"/search/user"} element={<SearchUserInfo />} />
                            <Route path={"/add"} element={<AddAudits />} />
                            <Route path={"/search/tree"} element={<SearchUserReferrals />} />
                        </Routes>
                    </main>
                </div>
            </AdminSidebar>
        </>
    );
};

export { Admin };