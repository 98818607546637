import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import SearchBar from '../../../utils/SearchBar';
import {Box, Skeleton} from '@mui/material';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import config from "../../../../config";
import { useSnackbar } from 'notistack';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DialogWithWaveBackground from "../../../utils/DialogWithWaveBackground";
import AddBalanceForm from "../../../utils/Forms/AddBalanceForm";
import TreeStructure from "../../../utils/Tree/TreeStructure";


const SearchUserReferrals = () => {
    const [results, setResults] = useState({
        children: [],
        code: "",
        email: "",
        join_date: "",
        name: "",
        packs_volume: "",
        phone: "",
        rank: '',
        reinvesting: 0,
        username: ""
        }
    );
    const location = useLocation();
    const [searchValue, setSearchValue] = useState(new URLSearchParams(location.search).get('search') || '');
    const [hasResult, setHasResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [openRelocate, setOpenRelocate] = useState(false);
    const handleOpenRelocate = () => setOpenRelocate(true);
    const handleCloseRelocate = () => setOpenRelocate(false);


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleSubmit = useCallback(async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${config.baseURL}/api/v1/search/network`, { query: searchValue });
            const data = response.data.data;
            setResults(data);
            console.log(data);
            setHasResult(true);

        } catch (response: any) {
            setError('User not found');
            setHasResult(false);
            enqueueSnackbar(error, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [enqueueSnackbar, error, searchValue]);

    return (
        <Box flexGrow={1} m="10px">
            <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{xs: '10px', sm: '10px', md: '10px'}}>
                <Grid xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px',
                        backgroundColor: '#285c9a',
                        minHeight: '96px'
                    }} width="100%" height="100%" paddingRight={"23px"}>
                        <Box sx={{alignItems: 'left'}}>
                            {hasResult && (
                                <>
                                    <Button sx={{
                                        backgroundColor: '#ffffff', marginRight: '10px', fontWeight: 'bold',
                                        '&:hover': {
                                            backgroundColor: '#96a4b6',
                                        }
                                    }} onClick={handleOpenRelocate}>
                                        <AccountTreeIcon sx={{marginRight: '10px'}}/>
                                        Relocate User
                                    </Button>
                                </>
                            )}
                        </Box>
                        <Box sx={{alignItems: 'right'}}>
                            <SearchBar value={searchValue} onChange={handleSearchChange} onSubmit={handleSubmit}/>
                        </Box>
                    </Box>
                </Grid>
                {loading ? (
                    <Grid container alignItems="flex-start" xs={12} md={12}>
                        <Grid xs={12} md={12}>
                            <Skeleton variant="rectangular" width="100%" height={800} />
                        </Grid>
                    </Grid>
                ) : hasResult && (
                    <Grid container alignItems="flex-start" xs={12} md={12}>
                        <Grid xs={12} md={12}>
                            <TreeStructure data={results}/>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <DialogWithWaveBackground open={openRelocate} onClose={handleCloseRelocate}>
                <AddBalanceForm username={results.username} onClose={handleCloseRelocate}/>
            </DialogWithWaveBackground>
        </Box>
    );
};

export default SearchUserReferrals;