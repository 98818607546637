import { Input } from '@mui/material';
import styled from '@emotion/styled';

export const Body = styled.body`
    background: linear-gradient(90deg, #dddddd, #428ee4);
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Screen = styled.div`
    background: linear-gradient(90deg, #285c9a, #428ee4);
    position: relative;
    height: 600px;
    width: 360px;
    box-shadow: 0 0 24px #285c9a;
    border-radius: 19px;
`;

export const ScreenContent = styled.div`
    z-index: 1;
    position: relative;
    height: 100%;
`;

export const ScreenBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
`;


export const ScreenBackgroundShape1 = styled.span`
  transform: rotate(45deg);
  position: absolute;
  height: 520px;
  width: 520px;
  background: #dddddd;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
`;

export const ScreenBackgroundShape2 = styled.span`
  transform: rotate(45deg);
  position: absolute;
  height: 220px;
  width: 220px;
  background: #285c9a;
  top: -172px;
  right: 0;
  border-radius: 32px;
`;

export const ScreenBackgroundShape3 = styled.span`
  transform: rotate(45deg);
  position: absolute;
  height: 540px;
  width: 190px;
  background: linear-gradient(270deg, #428ee4, #285c9a);
  top: -24px;
  right: 0;
  border-radius: 32px;
`;

export const ScreenBackgroundShape4 = styled.span`
    transform: rotate(45deg);
    position: absolute;
    height: 400px;
    width: 200px;
    background: #285c9a;
    top: 420px;
    right: 50px;
    border-radius: 60px;
`;


export const MainLogo = styled.img`
  display: block;
  //margin-left: auto;
  //margin-right: auto;
  //  margin: 10px;
  width: 60%;
  position: absolute;
`;

export const LoginForm = styled.form`
    width: 320px;
    padding: 156px 30px 30px;
`;

export const LoginField = styled.div`
  padding: 20px 0;
  position: relative;
`;

export const LoginInput = styled.input`
  border: none;
  border-bottom: 2px solid #285c9a;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  width: 60%;
  transition: .2s;
`;

export const LoginIcon = styled.i`
    position: absolute;
    left: 0;
    top: 30px;
    color: #285c9a;
`;

export const LoginForgetPassword = styled.a`
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  color: #285c9a;
  text-decoration: none;
`;

export const LoginSubmitButton = styled.button`
    background: #fff;
    font-size: 14px;
    //margin-top: 30px;
    padding: 12px 20px;
    border-radius: 20px;
    border: 1px solid #285c9a;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 80%;
    color: #285c9a;
    box-shadow: 0 2px 2px #285c9a;
    cursor: pointer;
    transition: .2s;
`;

export const SocialLogin = styled.div`
  position: absolute;
  height: 90px;
  width: 160px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  color: #fff;
`;

export const SocialIconsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SocialIcon = styled.a`
  padding: 20px 10px;
  color: #fff;
  text-decoration: none;
  text-shadow: 0 0 8px #7875B5;
`;

export const ButtonIcon = styled.i`
  font-size: 24px;
  margin-left: auto;
  color: #285c9a;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 4rem;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(15, 15, 15, 0.6);
  background-color: #1c1c1c;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
`;

export const StyledInput = styled(Input)`
    padding: 20px 0;
    color: white;
    position: relative !important;
`;

export const ErrorText = styled.span`
  color: #eb5d5d;
  font-size: 18px;
`;

// export const ErrorText = styled.div`
//     display: none;
//     position: fixed;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background-color: #f44336;
//     color: white;
//     padding: 20px;
//     border-radius: 5px;
//     text-align: center;
//     z-index: 1;
// `;
