import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import axiosInstance from '../Instances/axiosInstance';
import { useAuth } from './AuthContext';

interface UserContextProps {
    user: {
        name: string;
        ref_id: string;
        user_volume_rank: string;
        role: string;
        username: string;
    } | null;
    setUser: (user: {
        name: string;
        ref_id: string;
        user_volume_rank: string;
        role: string;
        username: string;
    } | null) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<UserContextProps['user']>(null);
    const { token } = useAuth();

    async function fetchUserData() {
        try {
            const response = await axiosInstance.get('/api/v1/info/user');
            console.log('User data:', response.data);
            setUser(response.data);
            localStorage.setItem('user', JSON.stringify(response.data));
        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    }

    useEffect(() => {
        if (token) {
            fetchUserData();
        }
    }, [token]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = (): UserContextProps => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};