import React, { ReactNode } from 'react';
import {
    Drawer,
    List,
    Box,
    IconButton,
    Typography
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { useMediaQuery } from '@mui/material';
import logo from '../../assets/logo.svg';
import '../../assets/css/Sidebar.css';
import { SidebarContext } from './SidebarContext'; // Import the context
import SidebarItem from './SidebarItem';
import HomeIcon from '@mui/icons-material/Home';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArticleIcon from '@mui/icons-material/Article';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MenuIcon from '@mui/icons-material/Menu'; // Import Icons

interface SidebarProps {
  children?: ReactNode;
}


const AdminSidebar: React.FC<SidebarProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const isMobile = useMediaQuery('(max-width:600px)');


    const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
      <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar: handleToggleSidebar }}>
    <Box display="flex">
        <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={isMobile ? isSidebarOpen : true}
            onClose={handleToggleSidebar}
            anchor="left"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: isSidebarOpen ? 200 : 60,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: isSidebarOpen ? 200 : 60,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    background: 'linear-gradient(-45deg, #005aff 30%, #FFFFFF 90%)' // Add this line for gradient background
                }
            }}
        >
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '10px'}}>
                <IconButton onClick={handleToggleSidebar}>
                    <MenuIcon sx={{color: '#093a8c'}} fontSize={'medium'}/>
                </IconButton>
                <img
                    src={logo}
                    alt="logo"
                    style={{
                        marginTop: '20px',
                        borderRadius: '50%',
                        width: isSidebarOpen ? '80%' : '50px',
                        height: isSidebarOpen ? 'auto' : '40px',
                        alignSelf: 'center'
                    }}
                />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px'}}>
                <Typography
                    variant="h5"
                    color={'#093a8c'}
                    fontWeight="bold"
                    sx={{ m: '10px 0 0 0' }}
                >
                    {isSidebarOpen ? 'Administration' : ''}
                </Typography>
            </Box>
            <List>
                <SidebarItem icon={<HomeIcon />} text="Panel Principal" path="/admin" isSidebarOpen={isSidebarOpen} />
                <SidebarItem icon={<ArticleIcon />} text="Auditoria" path="/admin/audits" isSidebarOpen={isSidebarOpen} />
                <SidebarItem icon={<PersonSearchIcon />} text="Buscar Usuario" path="/admin/search/user" isSidebarOpen={isSidebarOpen} />
                <SidebarItem icon={<AccountTreeIcon/>} text={'Buscar en Arbol'} path={'/admin/search/tree'} isSidebarOpen={isSidebarOpen} />

          </List>
        </Box>
        <Box>
            <List>
                <SidebarItem icon={<Logout />} text="Cerrar Sesion" path="/logout" isSidebarOpen={isSidebarOpen} />
            </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        {children}
      </Box>
    </Box>
      </SidebarContext.Provider>
  );
};

export default AdminSidebar;