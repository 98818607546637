// src/components/utils/WalletStatBox.tsx
import { Box, Typography, Skeleton } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import React from 'react';

interface StatBoxProps {
    amount: string;
    title: string;
    gain?: boolean;
    inmuted?: boolean;
    icon: React.ReactElement;
    isLoading: boolean;
}

const WalletStatBox: React.FC<StatBoxProps> = ({ amount, title, icon, gain, inmuted, isLoading }) => {
    return (
        <>
        {isLoading ? (
                <Skeleton variant={'rectangular'} width={'100%'} height={125} />
            ) : (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            padding: '10px',
            backgroundColor: '#285c9a',
            minHeight: `${inmuted ? '100px' : '125px'}`,
        }}>
            <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" sx={{color: '#FFFFFF'}}>
                    {icon}
                </Box>
                <Box>
                    <Typography
                        fontSize={'18px'}
                        fontWeight="bold"
                        sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}
                        marginLeft={'10px'}
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="left">
                    <Typography fontSize={'32px'} fontWeight="bold" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}>
                        ${parseFloat(amount).toLocaleString()}
                    </Typography>
                {gain && !isLoading && (
                    <Box display="flex" marginTop={'1px'}>
                        <TrendingUpIcon sx={{color: '#00ff00'}}/>
                        <Typography
                            fontSize={'16px'}
                            fontWeight="italic"
                            sx={{color: '#00ff00'}}
                            marginLeft={'5px'}
                        >
                            0.45%
                        </Typography>
                        <Typography
                            fontSize={'16px'}
                            fontWeight="italic"
                            sx={{color: '#96a4b6', whiteSpace: 'nowrap'}}
                            marginLeft={'5px'}
                        >
                            since last month
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
)}
        </>
    );
};

export default WalletStatBox;