// src/components/utils/SidebarItem.tsx
import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

interface SidebarItemProps {
    icon: React.ReactElement;
    text: string;
    path: string;
    isSidebarOpen: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, text, path, isSidebarOpen }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <ListItemButton
            sx={{
                minHeight: '38px',
                minWidth: '38px',
                borderLeft: location.pathname === path ? '4px solid #093a8c' : 'none',
                '.css-1f8bwsm': {
                    width: '38px',
                },
                '.css-cveggr-MuiListItemIcon-root': {
                    width: '38px',
            }

            }}
            onClick={() => navigate(path)}
        >
            <ListItemIcon>
                {React.cloneElement(icon, {
                    sx: { color: location.pathname === path ? '#093a8c' : '#252525', fontSize: '20px' }
                })}
            </ListItemIcon>
            {isSidebarOpen && (
                <ListItemText
                    primary={text}
                    sx={{ color: location.pathname === path ? '#093a8c' : '#252525', fontSize: '14px' }}
                />
            )}
        </ListItemButton>
    );
};

export default SidebarItem;