import React, { useState } from 'react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Box, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import '../assets/css/AuditTable.css';

interface Row {
    id: number;
    project: string;
    deadline: string;
    leaderTeam: string;
    budget: string;
    status: string;
}

interface AuditTableProps {
    rows: Row[];
}

const AuditTable: React.FC<AuditTableProps> = ({rows}) => {
    const [selectedRow, setSelectedRow] = useState<Row | null>(null);
    const handleClose = () => setSelectedRow(null);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1},
        { field: 'project', headerName: 'Project', flex: 1 },
        { field: 'deadline', headerName: 'Deadline', flex: 1 },
        { field: 'leaderTeam', headerName: 'Leader + Team', flex: 1 },
        { field: 'budget', headerName: 'Budget', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                const status = params.value;
                let statusClass = 'none';
                if (status === 'In progress') statusClass = 'in-progress';
                else if (status === 'Completed') statusClass = 'completed';
                else if (status === 'Blocked') statusClass = 'canceled';

                return (
                    <div onClick={() => setSelectedRow(params.row)} className="status-select-cursor">
                        <span className={`status-circle ${statusClass}`}></span>
                        {status}
                    </div>
                );
            },
        },
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }} className="data-grid-theme">
            <DataGrid
                rows={rows}
                columns={columns}
                density='compact'
                components={{
                    Toolbar: GridToolbar,
                }}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
            />
            <Dialog open={!!selectedRow} onClose={handleClose}>
                <DialogTitle>Row Details</DialogTitle>
                <DialogContent>
                    {selectedRow && (
                        <Box>
                            <Typography>ID: {selectedRow.id}</Typography>
                            <Typography>Project: {selectedRow.project}</Typography>
                            <Typography>Deadline: {selectedRow.deadline}</Typography>
                            <Typography>Leader + Team: {selectedRow.leaderTeam}</Typography>
                            <Typography>Budget: {selectedRow.budget}</Typography>
                            <Typography>Status: {selectedRow.status}</Typography>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default AuditTable;