// src/components/utils/CodeBox.tsx
import { Box, Typography, Skeleton } from '@mui/material';
import React from 'react';
import config from '../../config';
import { useSnackbar } from 'notistack';

interface CodeBoxProps {
    code: string;
    isParent?: boolean | undefined;
    isLoading?: boolean | undefined;
}

const CodeBox: React.FC<CodeBoxProps> = ({ code, isParent, isLoading }) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = () => {
        navigator.clipboard.writeText(`${config.siteURL}/register?referral=${code}`);
        if (isParent) enqueueSnackbar('Parent link copied to clipboard!', { variant: 'info' });
        else enqueueSnackbar('Referral link copied to clipboard!', { variant: 'info' });
    };

    return (
        <>
            {isLoading ? (
                <Skeleton variant={'rectangular'} width={'100%'} height={125} />
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    padding: '10px',
                    backgroundColor: '#285c9a',
                    minHeight: '125px',
                    cursor: 'pointer'
                }} onClick={handleClick}>
                    <Box display="flex">
                        <Box>
                            <Typography
                                fontSize={'20px'}
                                fontWeight="bold"
                                sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}
                            >
                                {isParent ? 'Parent user code:' : 'Referral user code:'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography fontSize={'40px'} fontWeight="bold" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}>
                            {code}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default CodeBox;