import * as React from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

function MyApp() {
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = () => {
        enqueueSnackbar('I love snacks.');
    };

    const handleClickVariant = (variant: VariantType) => () => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar('This is a success message!', { variant });
    };

    return (
        <React.Fragment>
            <Button onClick={handleClick}>Show snackbar</Button>
            <Button onClick={handleClickVariant('success')}>Show success snackbar</Button>
            <Button onClick={handleClickVariant('info')}>Show info snackbar</Button>
            <Button onClick={handleClickVariant('warning')}>Show warning snackbar</Button>
            <Button onClick={handleClickVariant('error')}>Show error snackbar</Button>
        </React.Fragment>
    );
}


export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
            <MyApp />
        </SnackbarProvider>
    );
}
