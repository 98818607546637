// src/components/utils/PageBanner.tsx
import { Box, Skeleton, Typography } from '@mui/material';
import React from "react";

interface PageBannerProps {
    title: string;
    subtitle: string;
    isLoading?: boolean;
}

const PageBanner: React.FC<PageBannerProps> = ({ title, subtitle, isLoading }) => {
    return (
        <>
            {isLoading ? (
                <Skeleton variant={'rectangular'} width={'100%'} height={125} />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                        flexDirection: 'column',
                        textAlign: 'left',
                        borderRadius: '10px 10px 0 0',
                        backgroundColor: '#285c9a',
                        padding: '20px',
                    }}
                >
                    <Box>
                        <Typography
                            variant="h4"
                            color="#FFFFFF"
                            fontWeight="bold"
                            sx={{ mb: '5px' }}
                        >
                            {title}
                        </Typography>
                        <Typography variant="h6" color="#FFFFFF">
                            {subtitle}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
}

export default PageBanner;