import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {useAuth} from "../../context/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import SpainFlag from "../assets/SpainFlag.svg";
import USFlag from "../assets/USFlag.svg";

interface AccountMenuProps {
    name: string;
    role: string;
}

export default function AccountMenu({name,role}: AccountMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const location = useLocation();
    const {logout} = useAuth();

    const {t} = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    const toggleLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'es' : 'en';
        i18n.changeLanguage(newLanguage);
        setCurrentLanguage(newLanguage);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar {...stringAvatar(name)} sx={{ width: 42, height: 42, backgroundColor: '#093a8c' }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.8))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            ml: -1,
                            mr: 1
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 20,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <PersonIcon sx={{margin: '0px 5px 0px 0px'}}/>
                    Profile
                </MenuItem>
                <Divider />
                    {role === 'admin' && (
                        location.pathname !== '/admin' ? (
                        <MenuItem onClick={() => navigate('/admin')} >
                            <ListItemIcon>
                                <AdminPanelSettingsIcon fontSize="small" />
                            </ListItemIcon>
                            Administration
                        </MenuItem>
                    ) : (
                        <MenuItem onClick={() => navigate('/home')} >
                            <ListItemIcon>
                                <AdminPanelSettingsIcon fontSize="small" />
                            </ListItemIcon>
                            Home
                        </MenuItem>
                    ))}
                <MenuItem onClick={toggleLanguage}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {currentLanguage === 'en' ? (
                            <>
                                <img src={SpainFlag} alt="Spain Flag" width="20px" height="30px" />
                                <span style={{ marginLeft: '10px' }}>Español</span>
                            </>
                        ) : (
                            <>
                                <img src={USFlag} alt="US Flag" width="20px" height="30px" />
                                <span style={{ marginLeft: '10px' }}>English</span>
                            </>
                        )}
                    </Box>
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
