// src/components/utils/Carousel.tsx
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Typography, Skeleton } from '@mui/material';
import ActivePack from './ActivePack';
import axiosInstance from '../../Instances/axiosInstance';
import { useAuth } from '../../context/AuthContext';

interface CarouselProps {
    activePacks: {
        name: string;
        percentage: number;
        price: number;
        date: string;
        day_percentage: number;
        wallet: string;
        description: string[];
        referral_bonus: string[];
    }[] | null
}

const CarouselComponent: React.FC<CarouselProps> = ({ activePacks }) => {
    const [isLoadingPacks, setIsLoadingPacks] = React.useState(true);
    const [defaultActivePacks, setActivePacks] = React.useState<CarouselProps['activePacks']>(null);
    const { token } = useAuth();

    React.useEffect(() => {
        if (!activePacks) {
            getActivePacks();
        } else {
            setActivePacks(activePacks);
        }
    }, [activePacks, token]);

    async function getActivePacks() {
        try {
            const response = await axiosInstance.get('/api/v1/info/user/active_packs');
            setIsLoadingPacks(false);
            setActivePacks(response.data);
            console.log('Active Packs:', response.data);
        } catch (error: any) {
            console.error(error.response.data.msg);
        }
    }

    return (
        <Box height={'100%'}>
            {isLoadingPacks ? (
                <Skeleton variant="rectangular" width="100%" height={487} />
            ) : (
                !defaultActivePacks || defaultActivePacks.length === 0 ? (
                    <Typography variant="h6" color="textSecondary" align="center" sx={{ padding: '20px' }}>
                        No active packs available. Please purchase packs.
                    </Typography>
                ) : (
                    <Carousel
                        autoPlay={true}
                        animation="slide"
                        indicators={false}
                        interval={6000}
                        navButtonsAlwaysVisible={defaultActivePacks.length > 1}
                        cycleNavigation={defaultActivePacks.length > 1}
                        navButtonsProps={{
                            style: {
                                backgroundColor: 'rgba(0,0,0,0.2)',
                                color: 'white',
                                borderRadius: '50%',
                                padding: '10px',
                                margin: '0 10px',
                            },
                        }}
                    >
                        {defaultActivePacks.map((pack, index) => (
                            <Box key={index} height={'100%'}>
                                <ActivePack
                                    progress={pack.percentage * 100}
                                    name={pack.name}
                                    amount={pack.price}
                                />
                            </Box>
                        ))}
                    </Carousel>
                )
            )}
        </Box>
    );
};

export default CarouselComponent;