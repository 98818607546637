import React, { useState } from 'react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Box, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import '../../assets/css/AuditTable.css';
import CustomToolbar from './CustomToolbar';

interface Row {
    date: string;
    ref: string;
    description: string;
    operation: number;
    amount: number;
}

interface AuditTableProps {
    rows: Row[];
}

const AuditTable: React.FC<AuditTableProps> = ({rows}) => {
    const [selectedRow, setSelectedRow] = useState<Row | null>(null);
    const handleClose = () => setSelectedRow(null);
    const columns: GridColDef[] = [
        { field: 'date', headerName: 'date', flex: 1},
        { field: 'ref', headerName: 'ref', flex: 1 },
        { field: 'description', headerName: 'description', flex: 1 },
        { field: 'amount', headerName: 'amount', flex: 1, renderCell: (params) => {
        const value = params.value;
        return ('$' + value.toLocaleString());
        }
        },
    ];

    return (
        <Box sx={{ height: 400, width: '100%' }} className="data-grid-theme">
            <DataGrid
                rows={rows}
                columns={columns}
                density='compact'
                components={{
                    Toolbar: CustomToolbar,
                }}
                componentsProps={{
                    toolbar: { position: 'bottom' },
                }}
                getRowId={(row) => row.ref}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
            />
            <Dialog open={!!selectedRow} onClose={handleClose}>
                <DialogTitle>Row Details</DialogTitle>
                <DialogContent>
                    {selectedRow && (
                        <Box>
                            <Typography>date: {selectedRow.date}</Typography>
                            <Typography>ref: {selectedRow.ref}</Typography>
                            <Typography>description: {selectedRow.description}</Typography>
                            <Typography>amount: {selectedRow.amount}</Typography>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default AuditTable;