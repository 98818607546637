import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import WalletStatBox from "../../../utils/WalletStatBox";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../../../../Instances/axiosInstance";
import PageBanner from "../../../utils/PageBanner";
import {useSnackbar} from "notistack";

const Packs = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [packAmount, setPackAmount] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [backendMessage, setBackendMessage] = useState('');
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [availableMembership, setAvailableMembership] = useState([]);
  const [purchasedMembership, setPurchasedMembership] = useState('');
  const [openMembershipDialog, setOpenMembershipDialog] = useState(false);
  const [openMembershipOptionsDialog, setOpenMembershipOptionsDialog] = useState(false);
  const [selectedMembershipOption, setSelectedMembershipOption] = useState(null);

  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    ref_id: '',
    balance: { for_savings: 0.00, for_products: 0.00, for_withdraw: 0.00, total: 0.00, on_deposit: 0.00 },
  });
  const [fundType, setFundType] = useState('deposit');
  const { t } = useTranslation();

  async function getData() {
    try {
      const response = await axiosInstance.get('/api/v1/info/user/dashboard');
      setIsLoading(false);
      setUserData(response.data);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);
  const handlePacksPurchase = (product) => {
    // Open the dialog
    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  async function getProducts() {
    try {
      const response = await axiosInstance.get('/api/v1/info/products/packs');
      setIsLoading(false);
      setProducts(response.data);

    } catch (error) {
      console.error(error);
    }
  }

  const handleDialogSubmit = async () => {
    try {
      const response = await axiosInstance.post('/api/v1/purchase/packs', { amount: packAmount, wallet: fundType });
      setOpenDialog(false);
      enqueueSnackbar(response.data.message, { variant: 'success' });
      if (response.data.membership && typeof response.data.membership === 'string') {
        setPurchasedMembership(response.data.membership);
        setOpenMembershipDialog(true); // Open the membership dialog
      }
      if (response.data.membership_option) {
        setAvailableMembership(response.data.membership_option);
        setOpenMembershipOptionsDialog(true); // Open the membership options dialog
      }
      setIsLoadingDialog(false);

    } catch (error) {
      handleCloseDialog();
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  const handleMembershipSubmit = async () => {
    try {
      const response = await axiosInstance.post('/api/v1/membership/set', { membership: selectedMembershipOption });
      setBackendMessage(response.data.message);
    } catch (error) {
      handleCloseDialog();
      setBackendMessage(error.response.data.message);
      setOpenMessageDialog(true);
    }
  };

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
  };


  const getGradientColor = (productName) => {
    switch (productName) {
      case 'Enterprising':
        return 'linear-gradient(180deg, #f2f0f0 30%, #287233 90%)';
      case 'Executive':
        return 'linear-gradient(180deg, #f2f0f0 30%, #3E285C 90%)';
      case 'CEO':
        return 'linear-gradient(180deg, #f2f0f0 30%, #F6A700 90%)';
      default:
        return '#f2f0f0'; // default color if no match
    }
  };


  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Box m="10px" flexGrow={1}>
      <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
        <Grid xs={12}>
          <PageBanner title={t('packs_title')} subtitle={t('packs_subtitle')} isLoading={isLoading}/>
        </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <WalletStatBox
                    title={t("deposit_funds")}
                    amount={userData.balance.on_deposit}
                    inmuted={true}
                    icon={<AccountBalanceWalletIcon />}  isLoading={isLoading}/>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <WalletStatBox
                    title={t("reinvestment_funds")}
                  amount={userData.balance.reinvestment}
                    inmuted={true}
                  icon={<AccountBalanceWalletIcon />}  isLoading={isLoading}/>
            </Grid>
            {products.map((product, index) => (
              <React.Fragment key={index}>
                {/*<Grid container item xs={12} spacing={2}>*/}
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ background: getGradientColor(product.name)}} p="20px" borderRadius="8px" minHeight="300px" display="flex"
                       flexDirection="column" justifyContent="space-between">
                    <Box bgcolor="#285c9a" p="10px" borderRadius="8px" mb="10px">
                      <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }} color={'#FFFFFF'}>
                        {t(product.name)}
                      </Typography>
                    </Box>
                    {product.description.map((desc, i) => (
                      <Typography key={i} variant="body1" color="text.secondary" textAlign={'center'}>
                        {t(desc)}
                      </Typography>
                    ))}
                    <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                      <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                        {product.price}
                      </Typography>
                    </Box>
                      <Button variant="contained" color="primary" sx={{ mt: '10px', mx: 'auto' }}
                              onClick={() => handlePacksPurchase(product)}>
                        {t("dashboard_purchase_button")}
                      </Button>
                  </Box>
                </Grid>
                {/*</Grid>*/}
                {/*<Divider variant="middle" sx={{ my: 2 }} color={"#262626"} />*/}
              </React.Fragment>
            ))}
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        {isLoadingDialog ? (
          <Box display="flex" justifyContent="center" alignItems="center" m={'10px'}>
            <CircularProgress />
          </Box>
        ) : (
          <><DialogContent>
            <Typography variant={'body1'}>
              {t('packs_enter_amount')}
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              label={t('investment_amount')}
              type="number"
              fullWidth
              value={packAmount}
              onChange={(e) => setPackAmount(e.target.value)} />
            <Typography variant={'body1'}>
              {t('select_investment_wallet')}
            </Typography>
            <Select
              value={fundType}
              onChange={(e) => setFundType(e.target.value)}
              fullWidth
            >
              <MenuItem value={'deposit'}>{t('deposit_funds')}</MenuItem>
              <MenuItem value={'reinvestment'}>{t('reinvestment_funds')}</MenuItem>
            </Select>
          </DialogContent><DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t('cancel_button')}
            </Button>
            <Button onClick={handleDialogSubmit} color="primary">
              {t('submit_button')}
            </Button>
          </DialogActions></>
        )}
      </Dialog>
      <Dialog open={openMessageDialog} onClose={handleCloseMessageDialog}>
        <DialogContent>
          <Typography variant={'h5'}>
            {t(backendMessage)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageDialog} color="primary">
            {t("close_button")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openMembershipDialog} onClose={() => setOpenMembershipDialog(false)}>
        <DialogContent>
          <Typography variant={'h5'}>
            {t(purchasedMembership)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMembershipDialog(false)} color="primary">
            {t("close_button")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openMembershipOptionsDialog} onClose={() => setOpenMembershipOptionsDialog(false)}>
        <DialogContent>
          <Typography variant={'h5'}>
            {t("Select a Membership Option")}
          </Typography>
          <Box>
            {availableMembership.map((option, index) => (
                <Button
                    key={index}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSelectedMembershipOption(option);
                      handleMembershipSubmit();
                      setOpenMembershipOptionsDialog(false);
                    }}
                    sx={{ margin: '10px' }}
                >
                  {option}
                </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMembershipOptionsDialog(false)} color="primary">
            {t("close_button")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Packs;