import {Box} from "@mui/material";
import AuditTable from "../../../utils/AuditTable";
import React from "react";

const OnGoingAudits = () => {

    const rows = [
        { id: 1, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
        { id: 2, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'Blocked' },
        { id: 3, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
        { id: 4, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'Early stages' },
        { id: 5, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
        { id: 1, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
        { id: 2, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'Blocked' },
        { id: 3, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
        { id: 5, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
    ];

    return(
        <Box p={"10px"}>
            <AuditTable rows={rows}/>
        </Box>
    )
}
export default OnGoingAudits