import React, {useEffect, useState} from 'react';
import {
    Typography,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    Box,
    Backdrop,
    CircularProgress
} from '@mui/material';
import axios from "axios";
import config from "../../../config";
import {useSnackbar} from "notistack";

interface AddBalanceFormProps {
    username: string;
    onClose: () => void; // Add onClose prop
}

const AddBalanceForm: React.FC<AddBalanceFormProps> = ({ username, onClose }) => {
    const [accountType, setAccountType] = useState('');
    const [amount, setAmount] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleAccountTypeChange = (event: any) => {
        setAccountType(event.target.value);
    };

    const handleAmountChange = (event: any) => {
        const value = event.target.value;
        const formattedValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));
        setAmount(formattedValue);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${config.baseURL}/api/addbalance`, {
                username,
                accountType,
                amount: parseFloat(amount.replace(/,/g, ''))
            });
            console.log('Response:', response.data);
            onClose();
            enqueueSnackbar(`Added ${amount} to ${username} ${accountType} account`, { variant: 'success' });
        } catch (error) {
            console.error('Error adding balance:', error);
            enqueueSnackbar('Error adding balance', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setIsFormValid(accountType !== '' && amount !== '');
    }, [accountType, amount]);

    return (
        <Box>
            <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                Add balance to {username} account
            </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
                <FormControl fullWidth margin="normal" sx={{ flex: 1 }}>
                    <InputLabel id="account-type-label">Account Type</InputLabel>
                    <Select
                        labelId="account-type-label"
                        value={accountType}
                        onChange={handleAccountTypeChange}
                        label="Account Type"
                    >
                        <MenuItem value="reinvestment_wallet">Repurchase</MenuItem>
                        <MenuItem value="deposit_wallet">Deposit</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Amount"
                    value={amount}
                    onChange={handleAmountChange}
                    InputProps={{
                        inputProps: { inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' }
                    }}
                    sx={{ flex: 1 }}
                />
            </Box>
            <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} disabled={!isFormValid}>
                Submit
            </Button>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default AddBalanceForm;