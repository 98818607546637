import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';

const Suggestions = (props) => {
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [suggestions, setSuggestions] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = async () => {
    await axios.post(
      `${config.baseURL}/api/v1/form/suggestions`,
      {
        suggestion: suggestions,
      },
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      },
    ).then(response => {
      setSubmitted(true);

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      ) : (
        <Box m="10px">

          <Box
            display={smScreen ? 'flex' : 'block'}
            flexDirection={smScreen ? 'row' : 'column'}
            justifyContent={smScreen ? 'space-between' : 'start'}
            alignItems={smScreen ? 'center' : 'start'}
            m="10px 0"
            p="20px"
            backgroundColor="#285c9a"
          >
            <Box>
              <Typography
                variant="h2"
                color="#FFFFFF"
                fontWeight="bold"
                sx={{ mb: '5px' }}
              >
                {t("suggestions_title")}
              </Typography>
              <Typography variant="h5" color="#FFFFFF">
                {t("suggestions_subtitle")}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" alignItems={"center"}>
            <Box width={'50%'} height={'100%'}>
              <Box bgcolor={'#f5f5f5'} p={'20px'} borderRadius={'10px'} m={'20px'}>
                <Typography variant="h4" align="center">{t("suggestions_form_label")}</Typography>
                </Box>
              <Divider />
              <Box display="flex" justifyContent="center" m={'20px 0 0 0'} width={'100%'}>
                <TextField
                  label={t("suggestions_title")}
                  value={suggestions}
                  onChange={(e) => setSuggestions(e.target.value)}
                  variant="outlined"
                  style={{ marginBottom: '20px', width: '100%' }}
                  type={'text'}
                  required={true}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Button variant={'contained'} color={'primary'} justifyContent={'center'} onClick={handleSubmit}
                        disabled={!suggestions}>
                  {t("submit_button")}
                </Button>
              </Box>
            </Box>
          </Box>

        </Box>
      )}
        </>
  );
};

export default Suggestions;