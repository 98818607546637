import React from 'react';
import {Dialog, DialogContent, Box, Typography, IconButton} from '@mui/material';
import logo from '../assets/logo.svg';
import typo from '../assets/typo.svg';
import CloseIcon from '@mui/icons-material/Close';

interface DialogWithWaveBackgroundProps {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const DialogWithWaveBackground: React.FC<DialogWithWaveBackgroundProps> = ({ open, onClose, children }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={{ borderRadius: '20px'}}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#ffffff' }}>
                    <img src={logo} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
                    <img src={typo} alt="Typo" style={{ height: '30px' }} />
                    <IconButton
                        onClick={onClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ position: 'relative', minHeight: '200px' }}>
                    <DialogContent sx={{ position: 'relative', paddingBottom: '150px' }}>
                        {children}
                    </DialogContent>
                    <svg viewBox="0 0 1440 320" style={{ position: 'absolute', bottom: 0, width: '100%', height: 'auto' }}>
                        <path fill="#285c9a" fillOpacity="1" d="M0,192L48,186.7C96,181,192,171,288,176C384,181,480,203,576,213.3C672,224,768,224,864,213.3C960,203,1056,181,1152,160C1248,139,1344,117,1392,106.7L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                    </svg>
                </Box>
            </Box>
        </Dialog>
    );
};

export default DialogWithWaveBackground;