import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Main } from './components/pages/home';
import { Admin } from './components/pages/admin';
import Login from './components/pages/login/Login';
import { SnackbarProvider } from 'notistack';
import PrivateRoute from './components/PrivateRoute';
import { useAuth } from './context/AuthContext';

function App() {
  return (
      <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home/*" element={<PrivateRoute element={Main} />} />
            <Route path="/admin/*" element={<PrivateRoute element={Admin} />} />
          </Routes>
      </SnackbarProvider>
  );
}

export default App;