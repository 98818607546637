import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../../../../Instances/axiosInstance";
import Grid from "@mui/material/Unstable_Grid2";
import PageBanner from "../../../utils/PageBanner";
import CustomToolbar from "../../../utils/Tables/CustomToolbar";

const History = (props) => {
  const [transactionHistory, setTransactionHistory] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const getData = async () => {
    await axiosInstance.get(
      `/api/v1/info/user/transactions`,

    ).then(response => {
      if (response.status === 200) {
        setTransactionHistory(response.data);
        setIsLoading(false);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const columns = [
    { field: 'date', headerName: t('history_table_date'), width: 200 },
    { field: 'ref', headerName: t('history_table_reference'), width: 400 },
    {
      field: 'description',
      headerName: t('history_table_description'),
      width: 600,
      cellClassName: 'name-column--cell',
      renderCell: ({ row: { description } }) => {
        return <Typography>{t(description)}</Typography>;
      }
    },
    {
      field: 'amount',
      headerName: t('history_table_amount'),
      width: 100,
      renderCell: ({ row: { amount, operation } }) => {
        if (operation === 4) {
          return <Typography color={'red'}>-${parseFloat(amount).toLocaleString()}</Typography>;
        }
        if (operation === 2) {
          return <Typography color={'green'}>-${parseFloat(amount).toLocaleString()}</Typography>;
        } else {
          return <Typography color={'gray'}>+${parseFloat(amount).toLocaleString()}</Typography>;
        }
      },
    },
  ];

  useEffect(() => {
    getData().then(r => console.log(r));
  }, []);

  return (
    <Box m="10px">
      <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
        <Grid xs={12}>
          <PageBanner title={t('history_title')} subtitle={t('history_subtitle')} isLoading={isLoading}/>
        </Grid>
      {isLoading ? (
          <Grid xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
          </Grid>
      ) : (
          <Grid xs={12}>
            <Box sx={{ height: 400, width: '100%' }} className="data-grid-theme">
              <DataGrid
                  rows={transactionHistory}
                  columns={columns}
                  density='compact'
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { position: 'bottom' },
                  }}
                  getRowId={(row) => row.ref}
                  pageSize={50}
                  rowsPerPageOptions={[50]}
                  disableSelectionOnClick
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default History;
