import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageBanner from "../../../utils/PageBanner";

const Promotions = (props) => {
  const [editProfile, setEditProfile] = useState(false);
  const [editSignIn, setEditSignIn] = useState(false);
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { t, i18n } = useTranslation();


  return (
      <Box m="10px">
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      ) : (

          <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
            <Grid xs={12}>
              <PageBanner title={t('promotions_title')} subtitle={t('promotions_subtitle')} isLoading={isLoading}/>
            </Grid>
            <Grid xs={12}>
            </Grid>
          </Grid>
      )}
      </Box>
  );
};

export default Promotions;