import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface PrivateRouteProps {
    element: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Component }) => {
    const { token, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && !token) {
            navigate('/');
        }
    }, [token, loading, navigate]);

    if (loading) {
        return <div>Loading...</div>; // Or a loading spinner
    }

    return token ? <Component /> : null;
};

export default PrivateRoute;