// src/components/utils/SupportMenu.tsx
import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Tooltip, Dialog } from '@mui/material';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import TechnicalSupportForm from './Forms/TechnicalSupportForm';
import DialogWithWaveBackground from "./DialogWithWaveBackground";
import AdministrationSupportForm from "./Forms/AdministrationSupportForm";

const SupportMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAdministrationDialog, setOpenAdministrationDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSupportMessage = (message: string) => {
    if (message === 'Technical Support') {
      setOpenDialog(true);
    } if (message === 'Administration') {
      setOpenAdministrationDialog(true);
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Support options">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'support-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <HeadsetMicOutlinedIcon sx={{ color: '#093a8c' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="support-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.8))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              ml: -1,
              mr: 1
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 12,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleSupportMessage('Administration')}>Administrative Support</MenuItem>
        <MenuItem onClick={() => handleSupportMessage('Technical Support')}>Technical Support</MenuItem>
        <MenuItem onClick={() => handleSupportMessage('Recommendations')}>Recommendations</MenuItem>
      </Menu>
      <DialogWithWaveBackground open={openDialog} onClose={() => setOpenDialog(false)}>
        <TechnicalSupportForm onClose={() => setOpenDialog(false)}/>
      </DialogWithWaveBackground>
      <DialogWithWaveBackground open={openAdministrationDialog} onClose={() => setOpenAdministrationDialog(false)}>
        <AdministrationSupportForm onClose={() => setOpenAdministrationDialog(false)}/>
      </DialogWithWaveBackground>
    </React.Fragment>
  );
};

export default SupportMenu;