import React, {useState} from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AuditTable from '../../utils/AuditTable';
import AdminBox from "../../utils/AdminBox";
import BugReportIcon from "@mui/icons-material/BugReport";
import AdminStatBox from '../../utils/AdminStatBox';
import IntegrationNotistack from "../../utils/GlobalSnackBar";
import CodeBox from "../../utils/CodeBox";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Person from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { PieChart } from '@mui/x-charts/PieChart';
import Carousel from '../../utils/Carousel';
import ActivePack from "../../utils/ActivePack";

const rows = [
  { id: 1, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
  { id: 2, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'Blocked' },
  { id: 3, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
  { id: 4, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'Early stages' },
  { id: 5, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
  { id: 1, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
  { id: 2, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'Blocked' },
  { id: 3, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
  { id: 5, project: 'New Dashboard', deadline: '17th Oct, 15', leaderTeam: 'Myrtle Erickson\nUK Design Team', budget: '$4,670\nPaid', status: 'In progress' },
];

const AdminPanel = () => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<'error' | 'warning' | 'info'>('info');
  const [message, setMessage] = useState('');

  const handleClick = () => {
    setSeverity('error');
    setMessage('This is an error message');
    setOpen(true);
  };

  return (
        <Box flexGrow={1} m="10px">
          <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
            <Grid xs={12}>
              <AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>
            </Grid>
            <Grid xs={12} md={3}>
              {/*<AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>*/}
              <AdminStatBox icon={<MonetizationOnIcon sx={{fontSize: '30px'}}/>} title={"Total deposits"} amount={'100000'} monetary={true}/>
            </Grid>
            <Grid xs={6} md={2}>
              <AdminStatBox icon={<Person sx={{fontSize: '30px'}}/>} title={"Users"} amount={'500'} monetary={false}/>
            </Grid>
            <Grid xs={6} md={2}>
              <AdminStatBox icon={<AccountBalanceWalletIcon sx={{fontSize: '30px'}}/>} title={"Total Revenue"} amount={'4000'} monetary={false}/>
            </Grid>
            <Grid xs={12} md={3}>
              {/*<CodeBox code={'N000000'}/>*/}
            </Grid>
            <Grid xs={12} md={5} sx={{ height: '300px' }}>
              <Box sx={{ height: '100%', backgroundColor: '#252525' }}>
              {/*<AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>*/}
                <PieChart
                    series={[
                      {
                        data: [ { value: 80, color: 'orange' },
                          // { value: 10, color: 'blue' },
                          // { value: 10, color: 'red' },
                          { value: 20, color: 'green' }],
                        innerRadius: 30,
                        outerRadius: 100,
                        startAngle: 360,
                        endAngle: -360,
                      }
                    ]}
                />
              </Box>
            </Grid>
            <Grid xs={12} md={3} sx={{ height: '500px' }}>
                {/*<AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>*/}
                <ActivePack progress={29.99} name={'enterprising'} amount={2000}/>
            </Grid>
            <Grid xs={12} md={4} sx={{ height: '400px' }}>
              <Box sx={{ height: '500px'}}>
                {/*<AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>*/}
                <Carousel activePacks={null}/>
              </Box>
            </Grid>
            <Grid xs={12}>
              <AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>
            </Grid>
            <Grid xs={6}>
              <AuditTable rows={rows}/>
            </Grid>
          </Grid>
          {/*<Grid container rowSpacing={2.5} columnSpacing={{ xs: 2.5, sm: 2.5, md: 2.5 }}>*/}
          {/*  <Grid item xs={12} md={4}>*/}
          {/*    <AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={12} md={4}>*/}
          {/*    <AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={12} md={4}>*/}
          {/*    <AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
          <Box p={"10px 0 0 0"}>
            <AdminBox icon={<BugReportIcon />} title={"Test test"} onClick={handleClick}/>
          </Box>
          <Box p={"10px 0 0 0"}>
            <AuditTable rows={rows}/>
          </Box>
            <IntegrationNotistack />
        </Box>
  );
};

export default AdminPanel;