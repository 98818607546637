import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import WalletStatBox from "../../../utils/WalletStatBox";
import axiosInstance from "../../../../Instances/axiosInstance";
import PageBanner from "../../../utils/PageBanner";

const Deposit = (props) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [amount, setAmount] = useState('');
  const [hash, setHash] = useState('');
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    ref_id: '',
    balance: { for_savings: 0.00, for_products: 0.00, for_withdraw: 0.00, total: 0.00 },
  });
  const [coin, setCoin] = useState('USDT');
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);

  const { t } = useTranslation();

  async function getData() {
    try {
      const response = await axiosInstance.get('/api/v1/info/user/dashboard');
      setIsLoading(false);
      setUserData(response.data);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getData().then(r => console.log(r));
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const validatePayment = async () => {
    setIsLoadingDialog(true);
    setOpen(true);
    try {
      await axiosInstance.post('/api/v1/funds/deposit', {
        amount: amount,
        hash: hash,
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoadingDialog(false)
    }
  };

  return (
    <Box m="10px" flexGrow={1}>
      <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
        <Grid xs={12}>
          <PageBanner title={t('deposit_title')} subtitle={t('deposit_subtitle')} isLoading={isLoading}/>
        </Grid>

      {/* First box with QR code and deposit button */}
      <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <WalletStatBox
            amount={userData.balance.on_deposit}
            title={t("deposit_funds")}
            inmuted={true}
            icon={<AccountBalanceWalletIcon />}
           isLoading={isLoading}/>
      </Grid>
      <Box width={'100%'} height={'100%'} alignItems={'center'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box minHeight={'250px'} minWidth={'250px'} m={'20px'} display="flex" justifyContent="center"
                 alignItems="center">
              <img alt={'QR Payment'} src={process.env.PUBLIC_URL + '/qr.png'} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box width={'50%'} height={'100%'}>
                <Box bgcolor={'#f5f5f5'} p={'20px'} borderRadius={'10px'} m={'20px'}>
                  <Typography variant="h4" align="center"> {t("deposit_form_label1")} </Typography>
                  <Typography variant={'h6'} align="center"> {t("deposit_form_label2")} </Typography>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="center" m={'20px 0 0 0'}>
                  <Select
                    value={coin}
                    onChange={(e) => setCoin(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '20px', width: '50%', marginRight: '20px'}}
                  >
                    <MenuItem value={'USDT'}>USD Tether (USDT)</MenuItem>
                    {/*<MenuItem value={'ETH'}>Ethereum (ETH)</MenuItem>*/}
                  </Select>
                  <TextField
                    label={t("deposit_amount")}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    variant="outlined"
                    style={{ marginBottom: '20px' }}
                    type={'number'}
                    required={true}
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <TextField
                    label={t("deposit_transaction_hash")}
                    value={hash}
                    onChange={(e) => setHash(e.target.value)}
                    variant="outlined"
                    style={{ marginBottom: '20px', width: '70%', color: '#285c9a' }}
                    required={true}
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Button variant={'contained'} color={'primary'} justifyContent={'center'} onClick={validatePayment}
                          disabled={!amount || !hash}>
                    {t("deposit_validate_payment")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box bgcolor={'#f5f5f5'} p={'20px'} borderRadius={'10px'} m={'20px'}>
          <Typography variant="h6" align="center"> {t("deposit_info_label1")} </Typography>
          <Typography variant={'h2'} align="center" style={{ wordWrap: 'break-word' }}>TMoxHZjEXTw6roocsdnXEdPAo7pp2pUtXz</Typography>
        </Box>

        <Dialog onClose={handleClose} open={open}>
          {isLoadingDialog ? (
              <Box display="flex" justifyContent="center" alignItems="center" m={'10px'}>
                <CircularProgress />
              </Box>
          ) : (
              <><DialogTitle> {t("deposit_check_payment1")} </DialogTitle><DialogContent>
                <Typography variant={'h6'}>
                  {t("deposit_check_payment2")}
                </Typography>
              </DialogContent></>
      )}
        </Dialog>
      </Box>
      </Grid>
    </Box>
  );
};

export default Deposit;