/* src/components/utils/Sidebar/Sidebar.tsx */
import React, { ReactNode } from 'react';
import {
    Drawer,
    List,
    ListItemIcon,
    ListItemText,
    Box,
    IconButton,
    ListItemButton, Typography, styled
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import Logout from '@mui/icons-material/Logout';
import { useMediaQuery } from '@mui/material';
import logo from '../../assets/logo.svg';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../../assets/css/Sidebar.css';
import { useUser } from '../../../context/UserContext';
import { SidebarContext } from './SidebarContext'; // Import the context
import SidebarItem from './SidebarItem';
import { useLocation } from 'react-router-dom'; // Import useLocation
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import LocalGroceryStore from '@mui/icons-material/LocalGroceryStore';
import AppsIcon from '@mui/icons-material/Apps';
import InsertChart from '@mui/icons-material/InsertChart';
import Groups from '@mui/icons-material/Groups';
import ExitToApp from '@mui/icons-material/ExitToApp';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import AddBox from '@mui/icons-material/AddBox';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import MailOutline from '@mui/icons-material/MailOutline';
import Verified from '@mui/icons-material/Verified';
import Home from '@mui/icons-material/Home';
import Contacts from '@mui/icons-material/Contacts';
import Person from '@mui/icons-material/Person';
import HelpOutline from '@mui/icons-material/HelpOutline';

import QueryBuilder from '@mui/icons-material/QueryBuilder';
import MenuIcon from '@mui/icons-material/Menu';
import {useTranslation} from "react-i18next"; // Import Icons

interface SidebarProps {
  children?: ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const isMobile = useMediaQuery('(max-width:600px)');
  const location = useLocation(); // Get the current location
  const { t } = useTranslation();
  const { user } = useUser(); // Get the user object from the context

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar: handleToggleSidebar }}>
      <Box display="flex">
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? isSidebarOpen : true}
          onClose={handleToggleSidebar}
          anchor="left"
          className="no-scrollbar" // Add this line to apply the no-scrollbar class
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: isSidebarOpen ? 250 : 60,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: isSidebarOpen ? 250 : 60,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              background: 'linear-gradient(-45deg, #005aff 30%, #FFFFFF 90%)' // Add this line for gradient background
            }
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '10px' }}>
              <IconButton onClick={handleToggleSidebar}>
                <MenuIcon sx={{ color: '#093a8c' }} fontSize={'medium'} />
              </IconButton>
              <img
                src={logo}
                alt="logo"
                style={{
                  marginTop: '20px',
                  borderRadius: '50%',
                  width: isSidebarOpen ? '80%' : '50px',
                  height: isSidebarOpen ? 'auto' : '40px',
                  alignSelf: 'center'
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
              <Typography
                variant="h5"
                color={'#093a8c'}
                fontWeight="bold"
                sx={{ m: '10px 0 0 0' }}
              >
                {isSidebarOpen ? user?.username || '' : ''}
              </Typography>
            </Box>
            <List>
              <SidebarItem icon={<HomeIcon />} text={t("sidebar_dashboard")} path="/home" isSidebarOpen={isSidebarOpen} />
              <SidebarItem icon={<AddBox />} text={t('sidebar_packs')} path={'/home/packs'} isSidebarOpen={isSidebarOpen} />
              <SidebarItem icon={<QueryBuilder />} text={t('sidebar_promotional_packs')} path={'/home/packs/promotional'} isSidebarOpen={isSidebarOpen}/>
              <SidebarItem icon={<LocalGroceryStore />} text={t('sidebar_store')} path={'/home/store'} isSidebarOpen={isSidebarOpen} />
              <SidebarItem icon={<AppsIcon />} text={t('sidebar_active_packs')} path={'/home/activepacks'} isSidebarOpen={isSidebarOpen} />
              <SidebarItem icon={<InsertChart />} text={t('sidebar_transaction_history')} path={'/home/history'} isSidebarOpen={isSidebarOpen} />
              <SidebarItem icon={<Groups />} text={t('sidebar_referral_network')} path={'/home/network'} isSidebarOpen={isSidebarOpen} />
              <SidebarItem icon={<AccountBalanceWallet />} text={t('sidebar_deposit')} path={'/home/deposit'} isSidebarOpen={isSidebarOpen} />
              <SidebarItem icon={<MonetizationOn />} text={t('sidebar_withdraw')} path={'/home/withdraw'} isSidebarOpen={isSidebarOpen} />
              {/*<SidebarItem icon={<EmojiEvents />} text={t('sidebar_rank_prizes')} path={'/home/rackprizes'} isSidebarOpen={isSidebarOpen} />*/}
              {/*<SidebarItem icon={<MailOutline />} text={'Sugerencias'} path={'/home/suggestions'} isSidebarOpen={isSidebarOpen} />*/}
              {/*<SidebarItem icon={<Verified />} text={t('sidebar_promotions')} path={'/home/promotions'} isSidebarOpen={isSidebarOpen} />*/}
              <SidebarItem icon={<HelpOutline />} text={t('sidebar_faq')} path={'/home/faq'} isSidebarOpen={isSidebarOpen} />
            </List>
          </Box>
          <Box>
            <List>
              <SidebarItem icon={<Logout />} text={t('sidebar_logout')} path="/logout" isSidebarOpen={isSidebarOpen} />
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          {children}
        </Box>
      </Box>
    </SidebarContext.Provider>
  );
};

export default Sidebar;