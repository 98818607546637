import { Box, Typography, Skeleton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import axiosInstance from "../../Instances/axiosInstance";

interface MembershipBoxProps {
    membership?: string;
    register?: string;
}

interface MembershipData {
    membership: string;
    register: string;
}

const MembershipBox: React.FC<MembershipBoxProps> = ({ membership, register }) => {
    const [userMembership, setUserMembership] = useState<MembershipData>({ membership: '', register: '' });
    const [isLoading, setIsLoading] = useState(true);

    async function getMembershipData() {
        try {
            const response = await axiosInstance.get('/api/v1/info/membership');
            setUserMembership(response.data);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (membership === undefined || register === undefined) {
            getMembershipData();
        } else {
            setUserMembership({ membership, register: register || '' });
            setIsLoading(false);
        }
    }, [membership, register]);

    return (
        <>
            {isLoading ? (
                <Skeleton variant={'rectangular'} width={'100%'} height={125} />
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    padding: '10px',
                    backgroundColor: '#285c9a',
                    minHeight: '125px'
                }}>
                    <Box display="flex">
                        <Box>
                            <Typography
                                fontSize={'20px'}
                                fontWeight="bold"
                                sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}
                            >
                                {userMembership.membership ? 'Has a membership of:' : ''}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography fontSize={'28px'} fontWeight="bold" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}>
                            {userMembership.membership || 'No membership'}
                        </Typography>
                        <Typography fontSize={'16px'} fontWeight="bold" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}>
                            Payment date: {userMembership.register || ''}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default MembershipBox;