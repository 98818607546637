import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import axios from 'axios';
import config from '../../../../config';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../../../../Instances/axiosInstance";
import WalletStatBox from "../../../utils/WalletStatBox";
import PageBanner from "../../../utils/PageBanner";


const PromotionalPacks = (props) => {
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activePacks, setActivePacks] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [packAmount, setPackAmount] = useState('');
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [backendMessage, setBackendMessage] = useState('');
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    ref_id: '',
    balance: { for_savings: 0.00, for_products: 0.00, for_withdraw: 0.00, total: 0.00, on_deposit: 0.00 },
  });
  const [token, setToken] = useState('');
  const [fundType, setFundType] = useState('deposit');
  const { t, i18n } = useTranslation();

  const getData = async () => {
    await axiosInstance.get(
      `/api/v1/info/user/dashboard`,

    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setUserData(response.data);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  useEffect(() => {
    getData().then(r => console.log(r));
  }, []);
  const handlePacksPurchase = (product) => {
    // Open the dialog
    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getProducts = async () => {
    try {
      await axiosInstance.get(
        `/api/v1/info/products/promotional`,

      ).then(response => {
        setProducts(response.data);
        setIsLoading(false);
      });

    } catch (error) {
      console.error(error);
    }
  };

  const handleDialogSubmit = async () => {
    setIsLoadingDialog(true);
    // Send the data to the backend
    await axiosInstance.post(
      `/api/v1/purchase/promotional`,
      {
        amount: packAmount,
        wallet: fundType,
      },
    ).then(response => {
      // Close the dialog
      handleCloseDialog();
      // Open the Snackbar
      setBackendMessage(response.data.message);
      setIsLoadingDialog(false);
      setOpenMessageDialog(true);


    }).catch(error => {
      // Close the dialog
      handleCloseDialog();
      // Open the Snackbar
      setBackendMessage(error.response.data.message);
      setOpenMessageDialog(true);
    });
  };

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
  };

  // "#f2f0f0"
  const getGradientColor = (productName) => {
    switch (productName) {
      case 'Promotional Enterprising':
        return 'linear-gradient(180deg, #f2f0f0 30%, #06FA4F 90%)';
      case 'Promotional Executive':
        return 'linear-gradient(180deg, #f2f0f0 30%, #DA06FA 90%)';
      case 'Promotional CEO':
        return 'linear-gradient(180deg, #f2f0f0 30%, #FAF206 90%)';
      default:
        return '#f2f0f0'; // default color if no match
    }
  };


  useEffect(() => {
    getProducts().then(r => console.log(r));
  }, []);

  return (
    <Box m="10px">
      {/* HEADER */}
      <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
        <Grid xs={12}>
          <PageBanner title={t('promotional_packs_title')} subtitle={t('promotional_packs_subtitle')} isLoading={isLoading}/>
        </Grid>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      ) : (
        <Grid xs={12}>
            <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
              <WalletStatBox
                  amount={userData.balance.on_deposit}
                  title={t("deposit_funds")}
                  icon={<AccountBalanceWalletIcon />}
                  isLoading={isLoading}
                  inmuted={true}
              />
            </Grid>
          <Grid container spacing={2} m={'20px'}>
            {products.map((product, index) => (
              <React.Fragment key={index}>
                {/*<Grid container item xs={12} spacing={2}>*/}
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ background: getGradientColor(product.name)}} p="20px" borderRadius="8px" minHeight="300px" display="flex"
                       flexDirection="column" justifyContent="space-between">
                    <Box bgcolor="#285c9a" p="10px" borderRadius="8px" mb="10px">
                      <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }} color={'#FFFFFF'}>
                        {t(product.name)}
                      </Typography>
                    </Box>
                    {product.description.map((desc, i) => (
                      <Typography key={i} variant="body1" color="text.secondary" textAlign={'center'}>
                        {t(desc)}
                      </Typography>
                    ))}
                    <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                      <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                        ${parseInt(product.price).toLocaleString()}
                      </Typography>
                    </Box>
                      <Button variant="contained" color="primary" sx={{ mt: '10px', mx: 'auto' }}
                              onClick={() => handlePacksPurchase(product)}>
                        {t("dashboard_purchase_button")}
                      </Button>
                  </Box>
                </Grid>
                {/*</Grid>*/}
                {/*<Divider variant="middle" sx={{ my: 2 }} color={"#262626"} />*/}
              </React.Fragment>
            ))}
          </Grid></Grid>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        {isLoadingDialog ? (
          <Box display="flex" justifyContent="center" alignItems="center" m={'10px'}>
            <CircularProgress />
          </Box>
        ) : (
          <><DialogContent>
            <Typography variant={'body1'}>
              {t('packs_enter_amount')}
            </Typography>
            <Select
                value={packAmount}
                onChange={(e) => setPackAmount(e.target.value)}
                fullWidth
            >
              <MenuItem value={5000}>${parseInt(5000).toLocaleString()}</MenuItem>
              <MenuItem value={10000}>${parseInt(10000).toLocaleString()}</MenuItem>
            </Select>
            {/*<TextField*/}
            {/*  autoFocus*/}
            {/*  margin="dense"*/}
            {/*  label={t('investment_amount')}*/}
            {/*  type="number"*/}
            {/*  fullWidth*/}
            {/*  value={packAmount}*/}
            {/*  onChange={(e) => setPackAmount(e.target.value)} />*/}
            <Typography variant={'body1'}>
              {t('select_investment_wallet')}
            </Typography>
            <Select
              value={fundType}
              onChange={(e) => setFundType(e.target.value)}
              fullWidth
            >
              <MenuItem value={'deposit'}>{t('deposit_funds')}</MenuItem>
              {/*<MenuItem value={'reinvestment'}>{t('reinvestment_funds')}</MenuItem>*/}
            </Select>
          </DialogContent><DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t('cancel_button')}
            </Button>
            <Button onClick={handleDialogSubmit} color="primary">
              {t('submit_button')}
            </Button>
          </DialogActions></>
        )}
      </Dialog>
      <Dialog open={openMessageDialog} onClose={handleCloseMessageDialog}>
        <DialogContent>
          <Typography variant={'h3'}>
            {t(backendMessage)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageDialog} color="primary">
            {t("close_button")}
          </Button>
        </DialogActions>
      </Dialog>
      </Grid>
    </Box>


  );
};

export default PromotionalPacks;