import { LineChart } from '@mui/x-charts/LineChart';
import axiosInstance from "../../Instances/axiosInstance";
import { useEffect, useState } from "react";
import {Skeleton} from "@mui/material";

const initialData = [
    {
        "id": "Enterprising",
        "color": "#287233",
        "data": [
            { "x": "Jan", "y": 120 },
            { "x": "Feb", "y": 600 },
            { "x": "Mar", "y": 100 },
            { "x": "Apr", "y": 180 },
            { "x": "May", "y": 0 },
            { "x": "Jun", "y": 0 },
            { "x": "Jul", "y": 0 },
            { "x": "Aug", "y": 0 },
            { "x": "Sep", "y": 0 },
            { "x": "Oct", "y": 0 },
            { "x": "Nov", "y": 0 },
            { "x": "Dec", "y": 0 }
        ]
    },
    {
        "id": "Executive",
        "color": "#3E285C",
        "data": [
            { "x": "Jan", "y": 500 },
            { "x": "Feb", "y": 200 },
            { "x": "Mar", "y": 600 },
            { "x": "Apr", "y": 1000 },
            { "x": "May", "y": 0 },
            { "x": "Jun", "y": 0 },
            { "x": "Jul", "y": 0 },
            { "x": "Aug", "y": 0 },
            { "x": "Sep", "y": 0 },
            { "x": "Oct", "y": 0 },
            { "x": "Nov", "y": 0 },
            { "x": "Dec", "y": 0 }
        ]
    },
    {
        "id": "CEO",
        "color": "#F6A700",
        "data": [
            { "x": "Jan", "y": 0 },
            { "x": "Feb", "y": 0 },
            { "x": "Mar", "y": 0 },
            { "x": "Apr", "y": 0 },
            { "x": "May", "y": 0 },
            { "x": "Jun", "y": 0 },
            { "x": "Jul", "y": 0 },
            { "x": "Aug", "y": 0 },
            { "x": "Sep", "y": 0 },
            { "x": "Oct", "y": 0 },
            { "x": "Nov", "y": 0 },
            { "x": "Dec", "y": 0 }
        ]
    }
];

export default function SimpleLineChart() {
    const [chartData, setChartData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(true);

    async function getGainsHistory() {
        try {
            const response = await axiosInstance.get('/api/v1/info/charts/line-chart');
            setChartData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getGainsHistory();
    }, []);

    const filteredData = chartData.map(item => ({
        ...item,
        data: item.data.filter((_, index) => chartData.some(series => series.data[index].y !== 0)),
    }));

    // const finalData = filteredData.filter(item => item.data.some(point => point.y !== 0));


    const series = filteredData.map(item => ({
        data: item.data.map(point => point.y),
        label: item.id,
        color: item.color,
        value: item.data.map(point => point.y)
    }));
    const xLabels = filteredData[0].data.map(point => point.x);

    return (
        <>
            {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height="100%" animation="wave"/>
            ) : (
             <LineChart series={series} xAxis={[{ scaleType: 'point', data: xLabels }]} sx={{background: '#FFFFFF'}}/>
            )}
        </>
    );
}