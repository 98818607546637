import { Box, Typography } from '@mui/material';
import React from 'react';

interface StatBoxProps {
    amount: string;
    title: string;
    monetary?: boolean;
    icon: React.ReactElement;
}

const AdminStatBox: React.FC<StatBoxProps> = ({ amount, title, icon , monetary}) => {

    return (
        <Box sx={{
            // Styling for the box
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            padding: '10px',
            backgroundColor: '#285c9a',
            minHeight: '125px'
        }}>
            <Box display="flex">
                <Box sx={{color: '#FFFFFF'}}>
                    {icon}
                </Box>
                <Box>
                    <Typography
                        fontSize={'20px'}
                        fontWeight="bold"
                        sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}
                        marginLeft={'10px'}
                    >
                        {title}
                    </Typography>
                </Box>

            </Box>
            <Box>
                <Typography fontSize={'32px'} fontWeight="bold" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap' }}>
                    {monetary ? '$' + parseFloat(amount).toLocaleString() : parseFloat(amount).toLocaleString()}
                </Typography>
            </Box>
        </Box>
    );
};

export default AdminStatBox;
