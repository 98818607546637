// src/components/pages/home/Home.tsx
import React, {useState} from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import WalletStatBox from '../../utils/WalletStatBox';
import IntegrationNotistack from "../../utils/GlobalSnackBar";
import CodeBox from "../../utils/CodeBox";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import SavingsIcon from '@mui/icons-material/Savings';
import Carousel from '../../utils/Carousel';
import PageBanner from "../../utils/PageBanner";
import axiosInstance from "../../../Instances/axiosInstance";
import {useUser} from '../../../context/UserContext';
import MembershipBox from "../../utils/MembershipBox";
import SimpleLineChart from "../../utils/MainLineChart";
import BarAnimation from "../../utils/MainBarChart";
import CountdownBox from "../../utils/CountdownBox";
import Verified from '@mui/icons-material/Verified';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userWallets, setUserWallets] = useState<any>({cycle_gains: 0, referrals_gains: 0});
  const { t } = useTranslation();
  const { user } = useUser();

  async function getData() {
    try {
      const response = await axiosInstance.get('/api/v1/info/wallets');
      setIsLoading(false);
      setUserWallets(response.data);

    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getData();
    }, []);

  return (
        <Box flexGrow={1} m="10px">
          <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
            <Grid xs={12}>
              <PageBanner title={t('Welcome ') + user?.name} subtitle={t('Welcome to your DeFi dashboard')} isLoading={isLoading}/>
            </Grid>
            <Grid xs={12} md={3}>
              <WalletStatBox icon={<MonetizationOnIcon sx={{fontSize: '30px'}}/>} title={t("dashboard_cycle_gains")} amount={userWallets.cycle_gains} gain={false} isLoading={isLoading}/>
            </Grid>
            <Grid xs={12} md={3}>
              <WalletStatBox icon={<PersonAddIcon sx={{fontSize: '30px'}}/>} title={t("dashboard_referral_gains")} amount={userWallets.referrals_gains} gain={false} isLoading={isLoading}/>
            </Grid>
            <Grid xs={12} md={3}>
              <WalletStatBox icon={<AccountBalanceWalletIcon sx={{fontSize: '30px'}}/>} title={t("dashboard_total_revenue")} amount={userWallets.cycle_gains + userWallets.referrals_gains} gain={false} isLoading={isLoading}/>
            </Grid>
            <Grid xs={12} md={3}>
              <CodeBox code={user?.ref_id || ''} isLoading={isLoading}/>
            </Grid>
            <Grid container xs={12} md={12}>
              <Grid container xs={12} md={8}>
                <Grid xs={12} md={12} sx={{ height: '300px' }}>
                  <SimpleLineChart />
                </Grid>
                <Grid xs={12} md={7} sx={{ height: '200px' }}>
                  <BarAnimation />
                </Grid>
                <Grid xs={12} md={5} sx={{ height: '200px' }}>
                  <CountdownBox />
                </Grid>
              </Grid>
              <Grid xs={12} md={4} sx={{ height: '400px' }}>
                <Box sx={{ height: '500px'}}>
                  <Carousel activePacks={null}/>
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} md={3}>
              <WalletStatBox icon={<SavingsIcon sx={{fontSize: '30px'}}/>} title={t("dashboard_savings_funds")} amount={userWallets.savings_wallet} inmuted={true} isLoading={isLoading}/>
            </Grid>
            <Grid xs={12} md={3}>
              <WalletStatBox icon={<LocalGroceryStoreIcon sx={{fontSize: '30px'}}/>} title={t("dashboard_store_funds")} amount={userWallets.store_wallet} inmuted={true} isLoading={isLoading}/>
            </Grid>
            <Grid xs={12} md={3}>
              <WalletStatBox icon={<MonetizationOnIcon sx={{fontSize: '30px'}}/>} title={t("dashboard_withdraw_funds")} amount={userWallets.withdraw_wallet} inmuted={true} isLoading={isLoading}/>
            </Grid>
            <Grid xs={6} md={3}>
              <WalletStatBox icon={<Verified sx={{fontSize: '30px'}}/>} title={"Promotional Funds"} amount={userWallets.promotional_wallet} inmuted={true} isLoading={isLoading}/>
            </Grid>
            <Grid xs={6} md={3}>
              <MembershipBox  />
            </Grid>
            <Grid xs={12} md={3}>
              <WalletStatBox icon={<AccountBalanceWalletIcon sx={{fontSize: '30px'}}/>} title={"Total available funds"} amount={userWallets.savings_wallet + userWallets.store_wallet + userWallets.withdraw_wallet + userWallets.promotional_wallet} isLoading={isLoading}/>
            </Grid>
            <Grid xs={12} md={6}>
              {/*<CountdownBox />*/}
            </Grid>
          </Grid>
        </Box>
  );
};

export default Home;